import React, {useEffect, useState} from "react";
import {
	Box,
	MenuItem,
} from "@material-ui/core";

import {Select, TextField} from 'mui-rff';
import DatePicker from "../../shared/DatePicker"
import { toast } from 'react-toastify';
import MachinesService from "../../../services/Storage/MachinesService";
import Machine, {DbMachine} from "../../../models/Storage/Machine";
import {Condition, getConditionString} from "../../../common/consts/Enums";
import CardComponent from "../../shared/CardComponent";
import FormComponent from "../../shared/FormComponent";
import {useHistory, useParams} from "react-router-dom";
import {RoutesUrl} from "../../../common/consts/Routes";

export default function Machines() {
	let { id } = useParams();
	const { push } = useHistory();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [initial, setInitial] = useState<DbMachine | null>(null);

	useEffect(() => {
		if(id) {
			setIsLoading(true);
			MachinesService.find(id).then((val: DbMachine) => {
				setInitial(val);
			}).finally(() => setIsLoading(false));
		}
	}, [id])

	const onSubmit = async (values: Machine): Promise<boolean | undefined> => {
		try {
			if(id) {
				await MachinesService.findAndUpdate(id, new Machine(values).dataToSave())
				toast.success("Успешно редактиране на Машина");
				push(RoutesUrl.report.stock);
				return;
			}

			await MachinesService.store(new Machine(values));
			toast.success("Успешно добавяне");
			return true;
		} catch(e) {
			toast.error("Нещо се обърка");
			console.error(e);
			return false;
		}
	};

	const formFields = [
		{
			size: 6,
			field: (
				<TextField
					label="Наименование"
					name="name"
					margin="none"
					required
					disabled={!!id}
				/>
			),
			id: "name",
			validations: {
				required: true,
			},
		},
		{
			size: 6,
			field: (
				<TextField
					label="Количество"
					name="quantity"
					margin="none"
					type={"number"}
					required
				/>
			),
			id: "quantity",
			validations: {
				required: true,
			},
		},
		{
			size: 6,
			field: (
				<DatePicker
					name="date"
					label="Дата на заприхождаване"
					required
				/>
			),
			id: "date",
			validations: {
				required: true,
			},
		},
		{
			size: 12,
			field: (
				<Select
					name="condition"
					label="Състояние"
					required
				>
					<MenuItem value={Condition.New}>{getConditionString(Condition.New)}</MenuItem>
					<MenuItem value={Condition.Used}>{getConditionString(Condition.Used)}</MenuItem>
					<MenuItem value={Condition.Discarded}>{getConditionString(Condition.Discarded)}</MenuItem>
				</Select>
			),
			id: "condition",
			validations: {
				required: true,
			},
		},
	];

	return (
		<Box>
			<CardComponent title={`Машини и техника`} isLoading={isLoading}>
				<FormComponent formFields={formFields} handleSubmit={(values) => { return onSubmit(values) }} initialValues={id ? initial : {}}/>
			</CardComponent>
		</Box>
	);
}
