import React, {useEffect, useRef, useState} from "react";
import CardComponent from "../../shared/CardComponent";
import {Box, Divider, Typography} from "@material-ui/core";
import Row from "./Row";
import Employee from "../../../models/Staff/Employee";
import EmployeeService from "../../../services/Staff/EmployeeService";
import {TableComponentToPrint} from "../../ComponentToPrint/TableComponent";
import {
	getColumns,
	getEmployeeColumnsForExport, getEmployeeDataForExport,
} from "../../../common/Utils/ActionUtils";
import {CardHeaderActions} from "../../shared/CardHeaderActions";
import TableComponent, {Column} from "../../shared/TableComponent";
import AlertModal from "../../shared/AlertModal";
import {toast} from "react-toastify";
import FilterHeader from "../shared/FilterHeader";
import ManufactureService from "../../../services/Manufacture/ManufactureService";
import {firestore} from "firebase";
import {FirestoreFilter} from "../../../services/BaseService";
import {DBManufacture} from "../../../models/manufacture/Manufacture";


export default function Staff() {
	const [data, setData] = useState<Array<Employee>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [selected, setSelected] = useState<Employee | null>(null);
	const [openedModal, setOpenedModal] = useState<boolean>(false);
	const [filters, setFilters] = useState<Array<FirestoreFilter>>([]);

	const loadEmployeesTroughManufacture = async (): Promise<Array<Employee>> => {
		const manufactures = await ManufactureService.getWithArrayOfFilter<DBManufacture>(filters);
		if(manufactures.length) {
			const employeesId: Array<string> = [];
			const manufactureIds: Array<string> = [];

			manufactures.forEach((manufacture) => {
				manufactureIds.push(manufacture.id!);
				if(manufacture.employees.length) {
					manufacture.employees.forEach((emp) =>{
						employeesId.push(emp.id);
					});
				}
			})

			if(employeesId.length) {
				const filter = {
					opStr: "in",
					value: employeesId,
					fieldPath: firestore.FieldPath.documentId(),
				} as FirestoreFilter;

				const employees =  await EmployeeService.getWithArrayOfFilter<Employee>([filter]);
				employees.forEach((employee) => {
					employee.manufactureData = employee.manufactureData.filter((manufacture) => {
						return manufactureIds.includes(manufacture.id);
					});
				});

				return employees;
			}
		}
		return [];
	}

	useEffect(() => {
		setIsLoading(true);
		const promise = filters.length ? loadEmployeesTroughManufacture() : EmployeeService.getEmployees();
		promise
			.then(values => setData(values))
			.finally(() => setIsLoading(false));
	}, [filters])

	let componentRef = useRef<TableComponentToPrint | null>(null);

	const csvColumns = getEmployeeColumnsForExport();
	const tableColumns: Array<Column> = csvColumns.map((col) => {
		return {
			header: col.name,
			cell: () => <></>
		}
	});

	tableColumns.unshift({
		header: "",
		cell: () => <></>
	});

	tableColumns.push({
		header: "Действие",
		cell: () => (
			<></>
		)
	});

	const deleteEmployee = (employee: Employee) => {
		setSelected(employee);
		setOpenedModal(true);
	}

	const handleModalSuccess = async () => {
		if(!selected || !selected.id) return;
		const { id } = selected;
		try {
			await EmployeeService.delete(id);
			toast.success(`Успешно изтриване на ${selected.names}`);
			setData(
				data.filter((emp) => emp.id !== id )
			);
		} catch(e) {
			toast.error(`Неуспешно изтриване`);
		}

		setSelected(null);
		setOpenedModal(false);
	}

	const closeModal = () => {
		setSelected(null);
		setOpenedModal(false);
	}

	const renderHeaderActions = () => {
		if (!data.length) return;

		const csvData = getEmployeeDataForExport(data);
		const csvFileName = `Employees_${+new Date()}.csv`;

		return (
			<CardHeaderActions
				csvHeaders={getColumns(csvColumns)}
				csvFileName={csvFileName}
				csvData={csvData}
				componentRef={componentRef}
			/>
		);
	}

	return (
		<CardComponent title={"Производствена програма - Служители"} isLoading={isLoading} headerActions={renderHeaderActions()}>
			<Box>
				<AlertModal onClose={closeModal} onSuccess={handleModalSuccess} show={openedModal} title={'Изтриване'} >
					<Typography>Сигурни ли сте, че искате да изтриете {selected ? selected.names : ""} ?</Typography>
				</AlertModal>
				{
					data.length > 0 && (
						<div style={{display: "none"}}>
							<TableComponentToPrint
								ref={componentRef}
								data={getEmployeeDataForExport(data)}
								headers={getEmployeeColumnsForExport()}
								title={"Производствена програма - Служители"}
							/>
						</div>
					)
				}
				<FilterHeader onFilter={setFilters} />
				<Box marginBottom="15px" marginTop="15px">
					<Divider />
				</Box>
				<TableComponent data={data} columns={tableColumns} rowElement={(row, index) => <Row key={index} row={row} onDelete={(employee) => deleteEmployee(employee)} />} />
			</Box>
		</CardComponent>
	);
}
