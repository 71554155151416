import React, {useEffect, useState} from "react";
import {
	Box,
	makeStyles,
	MenuItem,
} from "@material-ui/core";

import {Select, TextField} from 'mui-rff';
import DatePicker from "../../shared/DatePicker"
import MaterialsService from "../../../services/Storage/MaterialsService";
import Material, {DbMaterial} from "../../../models/Storage/Material";
import { toast } from 'react-toastify';
import CardComponent from "../../shared/CardComponent";
import FormComponent from "../../shared/FormComponent";
import {getUnitString, Units} from "../../../common/consts/Enums";
import {useHistory, useParams, withRouter} from "react-router-dom";
import {RoutesUrl} from "../../../common/consts/Routes";

function Materials() {
	let { id } = useParams();
	const { push } = useHistory();
	const useStyles = makeStyles(() => ({
		container: {},
		title: {
			flexGrow: 1,
		},
		root: {
			minWidth: 250,
		},
		buttonHolder: {
			display: "flex",
			alignItems: "center",
			marginTop: 15,
			"& button ~ button": {
				marginLeft: 15,
			}
		}
	}));
	const classes = useStyles();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [material, setMaterial] = useState<DbMaterial | null>(null);

	useEffect(() => {
		if(id) {
			setIsLoading(true);
			MaterialsService.find(id).then((val: DbMaterial) => {
				setMaterial(val);
			}).finally(() => setIsLoading(false));
		}
	}, [id])

	const onSubmit = async (values: Material): Promise<boolean | undefined> => {
		try {
			if(id) {
				await MaterialsService.findAndUpdate(id, new Material(values).dataToSave())
				toast.success("Успешно редактиране на Материал");
				push(RoutesUrl.report.stock);
				return;
			}

			await MaterialsService.store(new Material(values));
			toast.success("Успешно добавяне на Материал");
			return true;
		} catch(e) {
			toast.error("Нещо се обърка");
			console.error(e);
			return false;
		}
	};

	const formFields = [
		{
			size: 6,
			field: (
				<TextField
					label="Наименование на материал"
					name="name"
					margin="none"
					required
					disabled={!!id}
				/>
			),
			id: "name",
			validations: {
				required: true,
			},
		},
		{
			size: 6,
			field: (
				<TextField
					label="Количество"
					name="quantity"
					margin="none"
					type={"number"}
					required
				/>
			),
			id: "quantity",
			validations: {
				required: true,
			},
		},
		{
			size: 6,
			field: (
				<DatePicker
					name="date"
					label="Дата на заприхождаване"
					required
				/>
			),
			id: "date",
			validations: {
				required: true,
			},
		},
		{
			size: 12,
			field: (
				<Select
					name="unit"
					label="Мерна единица"
					formControlProps={{margin: 'none'}}
					required
				>
					{
						Object.values(Units).filter((val: any) => Units[+val] !== undefined).map((val: any, index) => (
							<MenuItem value={val} key={index}>{getUnitString(val)}</MenuItem>
						))
					}
				</Select>
			),
			id: "unit",
			validations: {
				required: true,
			},
		},
		{
			size: 6,
			field: (
				<TextField
					label="Цена без ДДС на мерна единица"
					name="price"
					margin="none"
					type={"number"}
					required
				/>
			),
			id: "price",
			validations: {
				required: true,
			},
		},
	];

	return (
		<Box className={classes.container}>
			<CardComponent title={`Складиране на материали`} isLoading={isLoading}>
				<FormComponent formFields={formFields} handleSubmit={(values) => { return onSubmit(values) }} initialValues={id ? material : {}} />
			</CardComponent>
		</Box>
	);
}

export default withRouter(Materials);
