import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
	apiKey: "AIzaSyDBAVfI5cyRTEXr-i9U8-1UQnuyeHFYuho",
	authDomain: "manufacturing-39140.firebaseapp.com",
	projectId: "manufacturing-39140",
	storageBucket: "manufacturing-39140.appspot.com",
	messagingSenderId: "1066453502939",
	appId: "1:1066453502939:web:04a929d8d315870a4b75fe"
};

class Firebase {
	private static instance: Firebase;
	auth: app.auth.Auth;

	db: app.firestore.Firestore;

	private constructor() {
		app.initializeApp(config);
		this.auth = app.auth();
		this.db = app.firestore();
	}

	/**
	 * The static method that controls the access to the singleton instance.
	 *
	 * This implementation let you subclass the Singleton class while keeping
	 * just one instance of each subclass around.
	 */
	public static getInstance(): Firebase {
		if (!Firebase.instance) {
			Firebase.instance = new Firebase();
		}

		return Firebase.instance;
	}


	login(email: string, password: string) {
		return this.auth.signInWithEmailAndPassword(email, password);
	}

	logout() {
		return this.auth.signOut();
	}

	async register(name: string, email: string, password: string) {
		await this.auth.createUserWithEmailAndPassword(email, password);
		// eslint-disable-next-line no-unused-expressions
		this.auth.currentUser?.sendEmailVerification();
		return this?.auth?.currentUser?.updateProfile({
			displayName: name,
		});
	}

	async resetPassword(email: string) {
		return this.auth.sendPasswordResetEmail(email);
	}

	addQuote(quote: string) {
		if (!this.auth.currentUser) {
			// eslint-disable-next-line no-alert
			return alert("Not authorized");
		}

		return this.db.doc(`users/${this.auth.currentUser.uid}`).set({
			quote,
		});
	}

	isInitialized() {
		return new Promise((resolve) => {
			this.auth.onAuthStateChanged(resolve);
		});
	}

	getCurrentUser() {
		return this.auth.currentUser;
	}

	isUserVerified() {
		return this.auth.currentUser && this.auth.currentUser.emailVerified;
	}

	async getCurrentUserQuote() {
		const quote = await this.db.doc(`users/${this?.auth?.currentUser?.uid}`).get();
		return quote.get("quote");
	}


	public firestore() {
		return app.firestore;
	}
}

export default Firebase.getInstance();
