import React, { useEffect, useState } from "react";
import TableComponent, { Column } from "../shared/TableComponent";
import CardComponent from "../shared/CardComponent";
import ManufactureService from "../../services/Manufacture/ManufactureService";
import {DBManufacture} from "../../models/manufacture/Manufacture";
import { Box, Typography } from "@material-ui/core";
import { getStateString } from "../../common/consts/Enums";
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { momentFormat } from "../../common/Utils/FormUtils";
import { Link } from "react-router-dom";
import { RoutesUrl } from "../../common/consts/Routes";
import moment from "moment";
import AlertModal from "../shared/AlertModal";
import {toast} from "react-toastify";
import DeleteIcon from "@material-ui/icons/Delete";
import {firestore} from "firebase";
import {FirestoreFilter} from "../../services/BaseService";
import EmployeeService from "../../services/Staff/EmployeeService";
import InstrumentsService from "../../services/Storage/InstrumentsService";
import {DbInstrument} from "../../models/Storage/Instrument";
import Employee from "../../models/Staff/Employee";
import MachinesService from "../../services/Storage/MachinesService";
import {DbMachine} from "../../models/Storage/Machine";
import RawMaterialsService from "../../services/Storage/RawMaterialsService";
import MaterialsService from "../../services/Storage/MaterialsService";
import {DbMaterial} from "../../models/Storage/Material";
import {DbRawMaterial} from "../../models/Storage/RawMaterial";

function List() {
	const [data, setData] = useState<Array<DBManufacture>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [selected, setSelected] = useState<DBManufacture | null>(null);
	const [openedModal, setOpenedModal] = useState<boolean>(false);


	useEffect(() => {
		setIsLoading(true);
		ManufactureService.get().then(values => setData(values.map((man) => new DBManufacture(man)))).finally(() => setIsLoading(false));
	}, [])

	const columns: Array<Column> = React.useMemo(() => [
		{
			accessor: "name",
			cell: (val) => {
				return (
					<Typography variant="body1">{val}</Typography>
				)
			},
			header: "Наименование"
		},
		{
			accessor: "state",
			cell: (val: number) => {
				return (
					<Typography variant="body1">{getStateString(val)}</Typography>
				)
			},
			header: "Статус"
		},
		{
			accessor: "quantity",
			cell: (val) => {
				return (
					<Typography variant="body1">{val}</Typography>
				)
			},
			header: "Количество"
		},
		{
			accessor: "startDate",
			cell: (val) => {
				return (
					<Typography variant="body1">{moment(val).format(momentFormat)}</Typography>
				)
			},
			header: "Дата на започване"
		},
		{
			accessor: "endDate",
			cell: (val) => {
				return val ? (
					<Typography variant="body1">{moment(val).format(momentFormat)}</Typography>
				) : <></>
			},
			header: "Дата на приключване"
		},
		{
			accessor: "used",
			cell: (val) => {
				return (
					<Typography variant="body1">{val === false ? "Не" : "Да"}</Typography>
				)
			},
			header: "В Готова продукция"
		},
		{
			cell: (manufacture) => {
				const { id, used} = manufacture;
				return (
					<Box display="flex">
						{
							used && (
								<Link to={`${RoutesUrl.manufacture.edit(id)}`}>
									<Typography color="primary" title="Преглед"><VisibilityIcon /></Typography>
								</Link>
							)

						}
						{
							!used && (
								<Link to={`${RoutesUrl.manufacture.edit(id)}`}>
									<Typography color="primary" title="Редакция"><EditIcon /></Typography>
								</Link>
							)
						}
						{
							!used && (
								<Typography color="primary" title="Изтриване" onClick={() => deleteManufacture(manufacture)} style={{ cursor: "pointer" }}><DeleteIcon /></Typography>
							)
						}
					</Box>
				)
			},
			header: "Действие"
		},
	], []);

	const handleModalSuccess = async () => {
		if(!selected || !selected.id) return;
		const { id } = selected;
		try {
			await ManufactureService.delete(id);

			/** TODO: refactor later **/
			if(selected.employees.length) {
				const employeeIds = selected.employees.map(({id}) => id);
				const filter = {
					opStr: "in",
					value: employeeIds,
					fieldPath: firestore.FieldPath.documentId(),
				} as FirestoreFilter;
				const employees = await EmployeeService.getWithArrayOfFilter<Employee>([filter]);
				if(employees.length) {
					const promises: Array<Promise<void>> = [];
					for(const employee of employees) {
						if(!employee.id) continue;
						const manufactureData = employee.manufactureData.filter((manufacture) => manufacture.id !== id);
						const manufactureIds = employee.manufactureIds.filter((manufacture) => manufacture !== id);
						promises.push(EmployeeService.findAndUpdate(employee.id, {
							manufactureData,
							manufactureIds,
						}));
					}

					await Promise.all(promises);
				}
			}

			if(selected.instruments.length) {
				const instrumentIds = selected.instruments.map(({id}) => id);
				const filter = {
					opStr: "in",
					value: instrumentIds,
					fieldPath: firestore.FieldPath.documentId(),
				} as FirestoreFilter;
				const instruments = await InstrumentsService.getWithArrayOfFilter<DbInstrument>([filter]);
				if(instruments.length) {
					const promises: Array<Promise<void>> = [];
					for(const instrument of instruments) {
						if(!instrument.id) continue;
						const manufactureData = instrument.manufactureData.filter((manufacture) => manufacture.id !== id);
						const manufactureIds = instrument.manufactureIds.filter((manufacture) => manufacture !== id);
						promises.push(InstrumentsService.findAndUpdate(instrument.id, {
							manufactureData,
							manufactureIds,
						}));
					}

					await Promise.all(promises);
				}
			}

			if(selected.machines.length) {
				const machineIds = selected.machines.map(({id}) => id);
				const filter = {
					opStr: "in",
					value: machineIds,
					fieldPath: firestore.FieldPath.documentId(),
				} as FirestoreFilter;
				const machines = await MachinesService.getWithArrayOfFilter<DbMachine>([filter]);
				if(machines.length) {
					const promises: Array<Promise<void>> = [];
					for(const machine of machines) {
						if(!machine.id) continue;
						const manufactureData = machine.manufactureData.filter((manufacture) => manufacture.id !== id);
						const manufactureIds = machine.manufactureIds.filter((manufacture) => manufacture !== id);
						promises.push(MachinesService.findAndUpdate(machine.id, {
							manufactureData,
							manufactureIds,
						}));
					}

					await Promise.all(promises);
				}
			}

			if(selected.rawMaterials.length) {
				const rawMaterialIds = selected.rawMaterials.map(({id}) => id);
				const filter = {
					opStr: "in",
					value: rawMaterialIds,
					fieldPath: firestore.FieldPath.documentId(),
				} as FirestoreFilter;
				const rawMaterials = await RawMaterialsService.getWithArrayOfFilter<DbRawMaterial>([filter]);
				if(rawMaterials.length) {
					const promises: Array<Promise<void>> = [];
					for(const rawMaterial of rawMaterials) {
						if(!rawMaterial.id) continue;
						const manufactureData = rawMaterial.manufactureData.filter((manufacture) => manufacture.id !== id);
						const manufactureIds = rawMaterial.manufactureIds.filter((manufacture) => manufacture !== id);
						promises.push(RawMaterialsService.findAndUpdate(rawMaterial.id, {
							manufactureData,
							manufactureIds,
						}));
					}

					await Promise.all(promises);
				}
			}

			if(selected.materials.length) {
				const materialIds = selected.materials.map(({id}) => id);
				const filter = {
					opStr: "in",
					value: materialIds,
					fieldPath: firestore.FieldPath.documentId(),
				} as FirestoreFilter;
				const materials = await MaterialsService.getWithArrayOfFilter<DbMaterial>([filter]);
				if(materials.length) {
					const promises: Array<Promise<void>> = [];
					for(const material of materials) {
						const manufactureData = material.manufactureData.filter((manufacture) => manufacture.id !== id);
						const manufactureIds = material.manufactureIds.filter((manufacture) => manufacture !== id);
						promises.push(MaterialsService.findAndUpdate(material.id, {
							manufactureData,
							manufactureIds,
						}));
					}

					await Promise.all(promises);
				}
			}

			toast.success(`Успешно изтриване на ${selected.name}`);
			setData(
				data.filter((emp) => emp.id !== id )
			);
		} catch(e) {
			toast.error(`Неуспешно изтриване`);
		}

		setSelected(null);
		setOpenedModal(false);
	}

	const closeModal = () => {
		setSelected(null);
		setOpenedModal(false);
	}

	const deleteManufacture = (manufacture: DBManufacture) => {
		setSelected(manufacture);
		setOpenedModal(true);
	}

	return (
		<CardComponent title={`Списък текущи производства`} isLoading={isLoading}>
			<Box>
				<AlertModal onClose={closeModal} onSuccess={handleModalSuccess} show={openedModal} title={'Изтриване'} >
					<Typography>Сигурни ли сте, че искате да изтриете {selected ? selected.name : ""} ?</Typography>
				</AlertModal>
				<TableComponent data={data} columns={columns} />
			</Box>
		</CardComponent>
	)
}


export default List;
