import React, {useEffect, useState} from "react";
import {Collapse, List, ListItem, ListItemIcon, ListItemText, makeStyles,} from "@material-ui/core";
import {
	AddCircle,
	Commute,
	ContactSupport,
	Dashboard,
	Equalizer,
	ExpandLess,
	ExpandMore,
	GroupAdd,
	Inbox,
	VpnKey
} from "@material-ui/icons";
import {useHistory, useLocation} from "react-router-dom";
import {RoutesUrl} from "../../common/consts/Routes";
import {States} from "../../common/consts/Enums";

interface Route {
	route: string;
	label: string;
	icon: React.ReactNode;
	children?: Route[];
}


const routes: Route[] = [
	{
		label: "Складово стопанство",
		route: "/dashboarda",
		icon: <Dashboard />,
		children: [
			{
				label: "Складиране на материали",
				icon: <Dashboard />,
				route: RoutesUrl.storage.materials,
			},
			{
				label: "Складиране на суровини",
				icon: <Dashboard />,
				route: RoutesUrl.storage.rawMaterials,
			},
			{
				label: "Складиране на готова продукция",
				icon: <ContactSupport />,
				route: RoutesUrl.storage.production,
			},
			{
				label: "Наличност на инструменти",
				icon: <VpnKey />,
				route: RoutesUrl.storage.instruments,
			},
			{
				label: "Машини и техника",
				icon: <Commute />,
				route:  RoutesUrl.storage.machines,
			},
		]
	},
	{
		label: "Продукти / Суровини",
		route: RoutesUrl.report.products,
		icon: <Dashboard />,
	},
	{
		label: "Производство",
		route: "/dashboarda1",
		icon: <Dashboard />,
		children: [
			{
				label: "Текущо",
				icon: <Dashboard />,
				route: RoutesUrl.manufacture.list,
			},
			{
				label: "Ново",
				icon: <AddCircle />,
				route: RoutesUrl.manufacture.new,
			},
		]
	},
	{
		label: "Отчети",
		route: "otcheti",
		icon: <Equalizer />,
		children: [
			{
				label: "Наличности",
				icon: <Equalizer />,
				route: RoutesUrl.report.stock,
			},
			{
				label: "Складови Движения",
				icon: <Equalizer />,
				route: RoutesUrl.report.storageMovements,
			},
			{
				label: "Завършени поръчки",
				icon: <Equalizer />,
				route: RoutesUrl.report.manufactures(States.Finished),
			},
			{
				label: "Текущо производство",
				icon: <Equalizer />,
				route: RoutesUrl.report.manufactures(States.Started),
			},
			{
				label: "Планирано производство",
				icon: <Equalizer />,
				route: RoutesUrl.report.manufactures(States.Planned),
			},
			{
				label: "Служители",
				icon: <Equalizer />,
				route: RoutesUrl.report.staff,
			},
			{
				label: "Машини и Инструменти",
				icon: <Equalizer />,
				route: RoutesUrl.report.machinesInstruments,
			},
		],
	},
	{
		label: "Персонал",
		route: "irrelevantUrl",
		icon: <GroupAdd />,
		children: [
			{
				label: "Списък",
				icon: <GroupAdd />,
				route: RoutesUrl.staff.list,
			},
			{
				label: "Създаване",
				icon: <GroupAdd />,
				route: RoutesUrl.staff.create,
			},
		]
	}
];


// eslint-disable-next-line @typescript-eslint/naming-convention
export default function SideMenu() {
	const location = useLocation();
	const history = useHistory();


	const useStyles = makeStyles(() => ({
		icon: {
			minWidth: 30,
		},
		text: {
			whiteSpace: "nowrap",
		},
		root: {
			"&$disabled": {
				opacity: 1,
			},
		},
		disabled: {

		},
		child: {
			position: "relative",
			left: "6px",
			top: "-6px",
			fontSize: "20px",
		},
		nested: {
			paddingLeft: 25,
		},
	}));

	const classes = useStyles();

	const [open, setOpen] = useState("");

	const handleClick = (r: Route) => {
		const { route } = r;
		if (route === open) {
			setOpen("");
		} else {
			setOpen(r?.route || "");
		}
	}

	useEffect(() => {
		for(const route of routes) {
			if(route.children !== undefined) {
				const find = route.children.find((r) => `${r.route}` === location.pathname);

				if(find) {
					setOpen(find ? route.route : "");
					break;
				}

			}

			if(route.route === location.pathname) {
				setOpen(route.route);
				break;
			}
		}
	}, [location]);

	return (
		<List>
			{
				routes.map((r) => {
					if (r.children) {
						return (
							<div key={`${r.route}-container`}>
								<ListItem key={`${r.route}-dropdown-list-item`} button onClick={() => handleClick(r)} >
									<ListItemIcon style={{ minWidth: 30 }}>
										<Inbox />
									</ListItemIcon>
									<ListItemText primary={r.label} classes={{ root: classes.text }} />
									{ open === r.route ? <ExpandLess /> : <ExpandMore />}								
								</ListItem>
								<Collapse in={open === r.route} timeout="auto" unmountOnExit>
									<List component="div" disablePadding>
										{
											r.children.map((rC) => {
												const link = `${rC.route}`;
												return (
													<ListItem button key={rC.route} className={classes.nested} selected={location.pathname === link} classes={{ root: classes.root, disabled: classes.disabled }} disabled={!rC.route} onClick={() => (rC.route ? (history.push(link)) : false)}>
														<ListItemIcon classes={{ root: classes.icon }}>{rC.icon}</ListItemIcon>
														<ListItemText primary={rC.label} />
													</ListItem>
												)
											})
										}
									</List>
								</Collapse>
							</div>
						)
					}

					return (
						<ListItem button key={`${r.route}-normal-list-item`} selected={location.pathname === r.route} onClick={() => (r.route ? (history.push(r.route)) : false)} disabled={r.route === undefined} classes={{ root: classes.root, disabled: classes.disabled }}>
							<ListItemIcon classes={{ root: classes.icon }}>{r.icon}</ListItemIcon>
							<ListItemText primary={r.label} classes={{ root: classes.text }} />
						</ListItem>
					)
				})
			}
		</List>
	);
}	
