import React, {useEffect, useRef, useState} from "react";
import CardComponent from "../../shared/CardComponent";
import { States } from "../../../common/consts/Enums";
import {DBManufacture} from "../../../models/manufacture/Manufacture";
import {Box,  Divider} from "@material-ui/core";
import ManufactureService from "../../../services/Manufacture/ManufactureService";
import Row from "./Row";
import FilterHeader from "../shared/FilterHeader";
import {TableComponentToPrint} from "../../ComponentToPrint/TableComponent";

import {
	getColumns,
	getManufactureColumnsForExport,
	getManufactureDataForExport
} from "../../../common/Utils/ActionUtils";
import {CardHeaderActions} from "../../shared/CardHeaderActions";
import TableComponent, {Column} from "../../shared/TableComponent";
import {FirestoreFilter} from "../../../services/BaseService";


export default function StorageMovements() {
	const [data, setData] = useState<Array<DBManufacture>>([]);

	const [filters, setFilters] = useState<Array<FirestoreFilter>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		setIsLoading(true);
		const defaultFilter: FirestoreFilter = {
			fieldPath: "state",
			opStr: "==",
			value: States.Finished
		};
		ManufactureService.getWithArrayOfFilter<DBManufacture>([...filters, defaultFilter])
			.then(values => setData(values.map((val) => new DBManufacture(val))))
			.finally(() => setIsLoading(false));
	}, [filters])

	let componentRef = useRef<TableComponentToPrint | null>(null);

	const csvColumns = getManufactureColumnsForExport();

	const tableColumns: Array<Column> = csvColumns.map((col) => {
		return {
			header: col.name,
			cell: () => <></>
		}
	});

	tableColumns.unshift({
		header: "",
		cell: () => <></>
	});

	tableColumns.push({
		header: "Действие",
		cell: () => <></>
	});

	const renderHeaderActions = () => {
		if (!data.length) return;

		const csvData = getManufactureDataForExport(data);
		const csvFileName = `StorageMovements_${+new Date()}.csv`;

		return (
			<CardHeaderActions
				csvHeaders={getColumns(csvColumns)}
				csvFileName={csvFileName}
				csvData={csvData}
				componentRef={componentRef}
			/>
		);
	}

	return (
		<CardComponent title={"Складови движения"} isLoading={isLoading} headerActions={renderHeaderActions()}>
			<Box>
				{
					data.length > 0 && (
						<div style={{display: "none"}}>
							<TableComponentToPrint
								ref={componentRef}
								data={getManufactureDataForExport(data)}
								headers={getManufactureColumnsForExport()}
								title={"Складови движения"}
							/>
						</div>
					)
				}
				<FilterHeader onFilter={setFilters} />
				<Box marginBottom="15px" marginTop="15px">
					<Divider />
				</Box>
				<TableComponent data={data} columns={tableColumns} rowElement={(row, index) => <Row key={index} row={row} />} />
			</Box>
		</CardComponent>
	);
}
