import BaseService from "../BaseService";
import {DbInstrument, DbInstrument as Instrument} from "../../models/Storage/Instrument";

class InstrumentsService extends BaseService {
	async getInstruments(): Promise<Array<Instrument>> {
		const values =  await this.db.collection(this.collectionName).get();
		const items: Array<Instrument> = [];
		values.forEach((val) => {
			const data = { id: val.id, ...val.data() };
			items.push(new Instrument(data as Instrument));
		});
		return items;
	}

	async find(id: string): Promise<DbInstrument> {
		const val =  await this.db.collection(this.collectionName).doc(id).get();
		const data = { id: val.id, ...val.data() };
		return new DbInstrument(data as DbInstrument);
	}
}

export default new InstrumentsService("instruments");
