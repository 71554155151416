import React from "react";
import TableRow from "@material-ui/core/TableRow";
import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, Typography } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Manufacture from "../../../models/manufacture/Manufacture";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { momentFormat } from "../../../common/Utils/FormUtils";
import { RoutesUrl } from "../../../common/consts/Routes";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import { ManufactureStorageData } from "../../../models/Storage/BaseStorageModel";

const useRowStyles = makeStyles({
	root: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
});

type Props = {
	row: Manufacture;
}


const renderCollapseTable = (title: string, data: Array<ManufactureStorageData>) => {
	return data.length > 0 && (
		<Box margin={1}>
			<Typography variant="h6" gutterBottom component="div">
				{title}
			</Typography>
			<Table size="small" aria-label="purchases">
				<TableHead>
					<TableRow>
						<TableCell>Наименование</TableCell>
						<TableCell>Количество</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data.map((element) => (
						<TableRow key={element.id}>
							<TableCell component="th" scope="row">
								{element.detailName}
							</TableCell>
							<TableCell>{element.quantity}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</Box>
	);
}

export default function Row(props: Props) {
	const { row } = props;
	const [open, setOpen] = React.useState(false);
	const classes = useRowStyles();

	const shouldRenderExtra = (): boolean => (row.materials.length > 0 || row.rawMaterials.length > 0);

	return (
		<React.Fragment>
			<TableRow className={classes.root}>
				<TableCell>
					{
						shouldRenderExtra() && (
							<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
								{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
							</IconButton>
						)
					}

				</TableCell>
				<TableCell>
					{row.name}
				</TableCell>
				<TableCell>{row.quantity}</TableCell>
				<TableCell>{moment(row.startDate).format(momentFormat)}</TableCell>
				<TableCell>
					{
						row.endDate && (
							moment(row.endDate).format(momentFormat)
						)
					}
				</TableCell>
				<TableCell>
					<Link to={`${RoutesUrl.manufacture.edit(row.id)}`}>
						<Typography color="primary"><EditIcon /></Typography>
					</Link>
				</TableCell>
			</TableRow>
			{
				shouldRenderExtra() && (
					<TableRow>
						<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>

							<Collapse in={open} timeout="auto" unmountOnExit>
								{
									renderCollapseTable("Материали", row.materials)
								}
								{
									renderCollapseTable("Суровини", row.rawMaterials)
								}
							</Collapse>
						</TableCell>
					</TableRow>
				)
			}
		</React.Fragment>
	);
}
