import React, {useEffect, useState} from "react";
import {
	Box,
	makeStyles,
	MenuItem,
} from "@material-ui/core";

import {Select, TextField} from 'mui-rff';
import DatePicker from "../../shared/DatePicker"
import { toast } from 'react-toastify';
import RawMaterial, {DbRawMaterial} from "../../../models/Storage/RawMaterial";
import RawMaterialsService from "../../../services/Storage/RawMaterialsService";
import CardComponent from "../../shared/CardComponent";
import FormComponent from "../../shared/FormComponent";
import {getUnitString, Units} from "../../../common/consts/Enums";
import {useHistory, useParams} from "react-router-dom";
import {RoutesUrl} from "../../../common/consts/Routes";

export default function RawMaterials() {
	const useStyles = makeStyles(() => ({
		container: {},
	}));

	const classes = useStyles();

	const { id } = useParams();
	const { push } = useHistory();
	const [rawMaterial, setRawMaterial] = useState<DbRawMaterial | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		if(id) {
			setIsLoading(true);
			RawMaterialsService.find(id).then((val: DbRawMaterial) => {
				setRawMaterial(val);
			}).finally(() => setIsLoading(false));
		}
	}, [id])

	const onSubmit = async (values: RawMaterial): Promise<boolean | undefined> => {
		try {
			if(id) {
				await RawMaterialsService.findAndUpdate(id, new RawMaterial(values).dataToSave())
				toast.success("Успешно редактиране на Материал");
				push(RoutesUrl.report.stock);
				return;
			}

			await RawMaterialsService.store(new RawMaterial(values));
			toast.success("Успешно добавяне на Суровина");
			return true;
		} catch(e) {
			toast.error("Нещо се обърка");
			console.error(e);
			return false;
		}
	};

	const formFields = [
		{
			size: 6,
			field: (
				<TextField
					label="Наименование на суровина"
					name="name"
					margin="none"
					required
					disabled={!!id}
				/>
			),
			id: "name",
			validations: {
				required: true,
			},
		},
		{
			size: 6,
			field: (
				<TextField
					label="Количество"
					name="quantity"
					margin="none"
					type={"number"}
					required
				/>
			),
			id: "quantity",
			validations: {
				required: true,
			},
		},
		{
			size: 6,
			field: (
				<DatePicker
					name="date"
					label="Дата на заприхождаване"
					required
				/>
			),
			id: "date",
			validations: {
				required: true,
			},
		},
		{
			size: 12,
			field: (
				<Select
					name="unit"
					label="Мерна единица"
					formControlProps={{margin: 'none'}}
					required
				>
					{
						Object.values(Units).filter((val: any) => Units[+val] !== undefined).map((val: any, index) => (
							<MenuItem value={val} key={index}>{getUnitString(val)}</MenuItem>
						))
					}
				</Select>
			),
			id: "unit",
			validations: {
				required: true,
			},
		},
		{
			size: 6,
			field: (
				<TextField
					label="Цена без ДДС на мерна единица"
					name="price"
					margin="none"
					type={"number"}
					required
				/>
			),
			id: "price",
			validations: {
				required: true,
			},
		},
	];

	// @ts-ignore
	return (
		<Box className={classes.container}>
			<CardComponent title={`Складиране на суровини`} isLoading={isLoading}>
				<FormComponent formFields={formFields} handleSubmit={(values) => { return onSubmit(values) }} initialValues={id ? rawMaterial : {}} />
			</CardComponent>
		</Box>
	);
}
