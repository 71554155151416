import BaseService from "../BaseService";
import {DbMaterial} from "../../models/Storage/Material";

class MaterialsService extends BaseService {
	async getMaterials(): Promise<Array<DbMaterial>> {
		const values =  await this.db.collection(this.collectionName).get();
		const empl: Array<DbMaterial> = [];
		values.forEach((val) => {
			const data = { id: val.id, ...val.data() };
			empl.push(new DbMaterial(data as DbMaterial));
		});
		return empl;
	}

	async find(id: string): Promise<DbMaterial> {
		const val =  await this.db.collection(this.collectionName).doc(id).get();
		const data = { id: val.id, ...val.data() };
		return new DbMaterial(data as DbMaterial);
	}
}

export default new MaterialsService("materials");
