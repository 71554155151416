import {FirestoreTimestamp, Model} from "../base.interface";
import moment from "moment";

export default class BaseStorageModel {
	public name: string = "";
	public quantity: number = 0;
	public date: Date | FirestoreTimestamp = new Date();
	public manufactureIds: Array<string> = [];
	public manufactureData: Array<ManufactureStorageData> = [];

	constructor(base?: BaseStorageModel) {
		if(base) {
			this.name = base.name;
			this.quantity = base.quantity;
			this.date = this.getDateValue(base.date);
			this.manufactureIds = base.manufactureIds || [];
			this.manufactureData = base.manufactureData || [];
		}
	}

	public getUsedQuantity(): number {
		if(!this.manufactureData.length) {
			return 0;
		}

		return this.manufactureData.reduce(((previousValue, currentValue) => {
			return previousValue + (+currentValue.quantity);
		}), 0)
	}

	protected getDateValue(availableDate: Date | FirestoreTimestamp) {
		const date = availableDate as FirestoreTimestamp;
		return date?.seconds ? moment(date.seconds * 1000).toDate() : availableDate;
	}

	protected getDefaultData() {
		return {
			name: this.name,
			quantity: this.quantity,
			date: this.date,
			manufactureIds: this.manufactureIds,
			manufactureData: this.manufactureData,
		}
	}
}

export class ManufactureStorageData implements Model {
	/** manufacture id **/
	public id: string;

	/** manufacture name **/
	public name: string;

	/** storage detail quantity **/
	public quantity: number;

	/** storage detail name **/
	public detailName: number;

	public constructor(data: any) {
		this.id = data.id;
		this.name = data.name;
		this.detailName = data.detailName;
		this.quantity = data.quantity;
	}


	dataToSave(): any {
		return {
			id: this.id,
			name: this.name,
			detailName: this.detailName,
			quantity: +this.quantity,
		}
	}
}
