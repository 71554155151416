import React from "react";
import {
	makeStyles, Avatar, Box, ButtonBase, Grid, Menu, MenuItem, Typography, Hidden, IconButton,
} from "@material-ui/core";
import {RouteComponentProps, withRouter} from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import firebase from "../firebase";
import SideMenu from "./SideMenu";
import avatar from "../../assets/avatar.png";

// eslint-disable-next-line @typescript-eslint/naming-convention
function AccountMenu(props: RouteComponentProps): JSX.Element {
	const useStyles = makeStyles(() => ({
		icon: {
			minWidth: 30,
		},
		text: {
			whiteSpace: "nowrap",
		},
		full: {
			width: "100%",
		},
	}));
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};


	const [anchorSideEl, setAnchorSideEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorSideEl);

	const handleSideClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorSideEl(event.currentTarget);
	};

	const handleSideClose = () => {
		setAnchorSideEl(null);
	};


	async function logout() {
		await firebase.logout();
		props.history.replace("/");
	}

	return (
		<Box paddingX="12px">
			<Grid container justify="center" wrap="nowrap">
				<Box paddingY="12px" flex={1}>
					<ButtonBase onClick={handleClick} className={classes.full}>
						<Grid container alignItems="center" wrap="nowrap">
							<Avatar alt="Travis Howard" src={avatar} />
							<Box marginLeft="10px"><Typography align="center" noWrap>Моят Профил</Typography></Box>
						</Grid>
					</ButtonBase>
					<Menu
						id="simple-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						<MenuItem onClick={logout}>Изход</MenuItem>
					</Menu>
				</Box>
				<Hidden mdUp>
					<IconButton
						aria-label="more"
						aria-controls="long-menu"
						aria-haspopup="true"
						onClick={handleSideClick}
					>
						<MoreVertIcon />
					</IconButton>
					<Menu
						id="long-menu"
						anchorEl={anchorSideEl}
						keepMounted
						open={open}
						onClose={handleSideClose}
					>
						<SideMenu />
					</Menu>
				</Hidden>
			</Grid>
		</Box>
	);
}

export default withRouter(AccountMenu);
