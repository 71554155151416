import React, { useState, useEffect } from "react";
import "./styles.css";
import { MuiThemeProvider } from "@material-ui/core/styles";
import {CssBaseline} from "@material-ui/core";
import {Switch, Route, BrowserRouter, Redirect} from "react-router-dom";
import Login from "../Login";
import Register from "../Register";
import EmailVerification from "../EmailVerification";
import Dashboard from "../Dashboard";
import ResetPassword from "../ResetPassword";
import firebase from "../firebase";
import outerTheme from "../../theme";
import {RoutesUrl} from "../../common/consts/Routes";
import {ToastContainer} from "react-toastify";
import Loader from "../shared/Loader";

const PrivateRoutes = () => {
	return (
		<Switch>
			<Route exact path={RoutesUrl.storage.materials} component={Dashboard} />
			<Route path={RoutesUrl.storage.editMaterials()} component={Dashboard} />
			<Route path={RoutesUrl.storage.rawMaterials} component={Dashboard} />
			<Route exact path={RoutesUrl.storage.editRawMaterials()} component={Dashboard} />
			<Route exact path={RoutesUrl.storage.production} component={Dashboard} />
			<Route exact path={RoutesUrl.storage.editProduction()} component={Dashboard} />
			<Route exact path={RoutesUrl.storage.instruments} component={Dashboard} />
			<Route exact path={RoutesUrl.storage.editInstruments()} component={Dashboard} />
			<Route exact path={RoutesUrl.storage.machines} component={Dashboard} />
			<Route exact path={RoutesUrl.storage.editMachines()} component={Dashboard} />
			<Route exact path={RoutesUrl.staff.list} component={Dashboard} />
			<Route exact path={RoutesUrl.staff.create} component={Dashboard} />
			<Route exact path={RoutesUrl.staff.edit()} component={Dashboard} />
			<Route exact path={RoutesUrl.manufacture.list} component={Dashboard} />
			<Route exact path={RoutesUrl.manufacture.new} component={Dashboard} />
			<Route exact path={RoutesUrl.manufacture.edit()} component={Dashboard} />
			<Route exact path={RoutesUrl.report.stock} component={Dashboard} />
			<Route exact path={RoutesUrl.report.manufactures()} component={Dashboard} />
			<Route exact path={RoutesUrl.report.storageMovements} component={Dashboard} />
			<Route exact path={RoutesUrl.report.staff} component={Dashboard} />
			<Route exact path={RoutesUrl.report.machinesInstruments} component={Dashboard} />
			<Route exact path={RoutesUrl.report.products} component={Dashboard} />
			<Route path="*">
				<Redirect to={RoutesUrl.manufacture.list}/>
			</Route>
		</Switch>
	)
}

const PublicRoutes = () => {
	return (
		<Switch>
			<Switch>
				<Route exact path="/" component={EmailVerification} />
				<Route exact path="/login" component={Login} />
				<Route exact path="/register" component={Register} />
				<Route exact path="/reset-password" component={ResetPassword} />
				<Route exact path="/sales-research" component={Dashboard} />
				<Route exact path="/database" component={Dashboard} />
				<Route path="*">
					<Redirect to={"/login"}/>
				</Route>
			</Switch>
		</Switch>
	)
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function App() {
	const [firebaseInitialized, setFirebaseInitialized] = useState(false);
	const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);

	useEffect(() => {
		firebase.isInitialized().then(() => {
			if(!firebaseInitialized) setFirebaseInitialized(true);
		});
	}, [firebase.auth.onAuthStateChanged((user) => {
		if(user) {
			setIsUserLoggedIn(!!user)
		} else {
			setIsUserLoggedIn(false);
		}
	})]);

	const routes = () => {
		if(!firebaseInitialized) return null;

		if(isUserLoggedIn) {
			return <PrivateRoutes />
		}

		return <PublicRoutes />
	}


	return firebaseInitialized ? (
		<MuiThemeProvider theme={outerTheme}>
			<CssBaseline />
			<ToastContainer />
			<BrowserRouter>
				{
					routes()
				}
			</BrowserRouter>
		</MuiThemeProvider>
	) : <Loader />
}
