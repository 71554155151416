export enum Condition {
	New = 1,
	Used,
	Discarded
}

export const MapInstrumentCondition: {[key in Condition]: string} = {
	[Condition.New]: "Нов",
	[Condition.Used]: "Използван",
	[Condition.Discarded]: "Бракуван",
};

export const getConditionString = (key: Condition): string => {
	return MapInstrumentCondition[key];
}


export enum States {
	Planned = 1,
	Started,
	Finished,
}

export const MapStateToString: {[key in States]: string} = {
	[States.Planned]: "Планирано",
	[States.Started]: "Започнато",
	[States.Finished]: "Завършено",
};

export const getStateString = (key: States): string => {
	return MapStateToString[key];
}

export enum Units {
	Count = 1,
	LinearMeter,
	SquareMeter,
	Kg,
	CubicMeter,
	Litre,
	Box,
	Package,
}

export const MapUnitsToString: {[key in Units]: string} = {
	[Units.Count]: "Брой",
	[Units.LinearMeter]: "Метър Линеен",
	[Units.SquareMeter]: "Метър Квадратен",
	[Units.Kg]: "Килограм",
	[Units.CubicMeter]: "Метър Кубичен",
	[Units.Litre]: "Литър",
	[Units.Box]: "Кашон",
	[Units.Package]: "Опаковка",
};

export const getUnitString = (key: Units): string => {
	return MapUnitsToString[key];
}
