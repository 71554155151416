import React from "react";
import TableRow from "@material-ui/core/TableRow";
import {Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, Typography} from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {makeStyles} from "@material-ui/core/styles";
import Employee, {ManufactureEmployeeData} from "../../../models/Staff/Employee";
import moment from "moment";
import {momentFormat} from "../../../common/Utils/FormUtils";
import {Link} from "react-router-dom";
import {RoutesUrl} from "../../../common/consts/Routes";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';

const useRowStyles = makeStyles({
	root: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
});

type Props = {
	row: Employee;
	onDelete?: (id: Employee) => void;
}


const renderCollapseTable = (title: string, data: Array<ManufactureEmployeeData>) => {
	return data.length > 0 && (
		<Box margin={1}>
			<Typography variant="h6" gutterBottom component="div">
				{title}
			</Typography>
			<Table size="small" aria-label="purchases">
				<TableHead>
					<TableRow>
						<TableCell>Производство</TableCell>
						<TableCell>Дейност</TableCell>
						<TableCell>Работни часове</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data.map((element) => (
						<TableRow key={element.id}>
							<TableCell scope="row">
								{element.name}
							</TableCell>
							<TableCell>
								{element.activity}
							</TableCell>
							<TableCell>{element.workingHours}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</Box>
	);
}

export default function Row(props: Props) {
	const { row } = props;
	const [open, setOpen] = React.useState(false);
	const classes = useRowStyles();

	const shouldRenderExtraRow = () => (row.manufactureData.length > 0);

	const handleDelete = () => {
		if(props.onDelete !== undefined) {
			props.onDelete(row);
		}
	}

	return (
		<React.Fragment>
			<TableRow className={classes.root}>
				<TableCell>
					{
						shouldRenderExtraRow() && (
							<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
								{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
							</IconButton>
						)
					}
				</TableCell>
				<TableCell scope="row">
					{row.names}
				</TableCell>
				<TableCell>
					{moment(row.start_date).format(momentFormat)}
				</TableCell>
				<TableCell>{row.department}</TableCell>
				<TableCell>{row.position}</TableCell>
				<TableCell>{row.other}</TableCell>
				<TableCell>
					{
						row.totalWorkingHours()
					}
				</TableCell>
				<TableCell>
					<Box display="flex">
						<Link to={`${RoutesUrl.staff.edit(row.id)}`} title="Редакция">
							<Typography color="primary"><EditIcon /></Typography>
						</Link>
						<Typography color="primary" title="Изтриване" onClick={() => handleDelete()} style={{ cursor: "pointer" }}><DeleteIcon /></Typography>
					</Box>
				</TableCell>
			</TableRow>
			{
				shouldRenderExtraRow() && (
					<TableRow>
						<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
							<Collapse in={open} timeout="auto" unmountOnExit>
								{
									renderCollapseTable("Производства", row.manufactureData)
								}
							</Collapse>
						</TableCell>
					</TableRow>
				)
			}
		</React.Fragment>
	);
}
