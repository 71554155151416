import React from "react";
import {Box, Button, makeStyles} from "@material-ui/core";
import {validateForm} from "../../common/Utils/FormUtils";
import { Form } from "react-final-form";

interface Props {
	formFields: Array<any>;
	customValidate?: any;
	handleSubmit: (values: any) => Promise<boolean | undefined>;
	initialValues?: any;
}

export default function FormComponent(props: Props) {
	const useStyles = makeStyles(() => ({
		buttonHolder: {
			display: "flex",
			alignItems: "center",
			marginTop: 15,
			"& button ~ button": {
				marginLeft: 15,
			}
		}
	}));
	const classes = useStyles();

	let setFieldVal: any;

	const validate = (values: any) => {
		if(props.customValidate) {
			return props.customValidate(values);
		}
		return validateForm(values, props.formFields);
	};

	const resetForm = (form: any, values: any = null) => {
		form.reset();
		if(values) {
			Object.keys(values).forEach(key => {
				form.change(key, undefined);
				form.resetFieldState(key);
			});
		}
	}

	const onSubmit = async (values: any, form: any) => {
		const result = await props.handleSubmit(values);
		if(result) {
			setTimeout(() => resetForm(form, values), 500);
		}
	}

	return (
		<Form
			onSubmit={onSubmit}
			validate={validate}
			initialValues={props.initialValues}
			render={({handleSubmit, form, submitting, pristine, invalid}) => {
				// put the reference on a window variable of your choice here
				if (!setFieldVal) setFieldVal = form;
				return (
					<form onSubmit={handleSubmit} noValidate>
						{props.formFields.map((item, idx) => (
							<Box marginBottom="15px" key={idx}>
								{item.field}
							</Box>
						))}
						<Box className={classes.buttonHolder}>
							<Button
								variant="contained"
								color="primary"
								type="submit"
								disabled={invalid}
							>
								Добави
							</Button>

							<Button
								type="button"
								variant="contained"
								onClick={() => {
									resetForm(form)
								}}
								disabled={submitting || pristine}
							>
								Нулиране
							</Button>
						</Box>
					</form>
				)

			}}
		/>
	)
}
