import BaseService from "../BaseService";
import {DbRawMaterial, DbRawMaterial as RawMaterial} from "../../models/Storage/RawMaterial";

class RawMaterialsService extends BaseService {
	async getRawMaterials(): Promise<Array<RawMaterial>> {
		const values =  await this.db.collection(this.collectionName).get();
		const empl: Array<RawMaterial> = [];
		values.forEach((val) => {
			const data = { id: val.id, ...val.data() };
			empl.push(new RawMaterial(data as RawMaterial));
		});
		return empl;
	}

	async find(id: string): Promise<DbRawMaterial> {
		const val =  await this.db.collection(this.collectionName).doc(id).get();
		const data = { id: val.id, ...val.data() };
		return new DbRawMaterial(data as DbRawMaterial);
	}
}

export default new RawMaterialsService("rawMaterials");
