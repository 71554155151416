import BaseService from "../BaseService";
import {DbMachine, DbMachine as Machine} from "../../models/Storage/Machine";

class MachinesService extends BaseService {
	async getMachines(): Promise<Array<Machine>> {
		const values =  await this.db.collection(this.collectionName).get();
		const items: Array<Machine> = [];
		values.forEach((val) => {
			const data = { id: val.id, ...val.data() };
			items.push(new Machine(data as Machine));
		});
		return items;
	}

	async find(id: string): Promise<DbMachine> {
		const val =  await this.db.collection(this.collectionName).doc(id).get();
		const data = { id: val.id, ...val.data() };
		return new DbMachine(data as DbMachine);
	}
}

export default new MachinesService("machines");
