import {FirestoreTimestamp, Model} from "../base.interface";
import {States} from "../../common/consts/Enums";
import moment from "moment";
import {ManufactureEmployeeData} from "../Staff/Employee";
import {ManufactureStorageData} from "../Storage/BaseStorageModel";

export default class Manufacture implements Model {
	public id?: string;
	public name!: string;
	public quantity!: string;
	public startDate!: FirestoreTimestamp | Date;
	public endDate!: FirestoreTimestamp | Date | null;
	public state: States = States.Planned;
	public employees: Array<ManufactureEmployeeData> = [];
	public materials: Array<ManufactureStorageData> = [];
	public rawMaterials: Array<ManufactureStorageData> = [];
	public instruments: Array<ManufactureStorageData> = [];
	public machines: Array<ManufactureStorageData> = [];
	public used = false;

	constructor(manufacture?: Manufacture) {
		if (manufacture) {
			if (manufacture.id) this.id = manufacture.id;
			this.name = manufacture.name;
			this.quantity = manufacture.quantity;
			const startDate = manufacture.startDate as FirestoreTimestamp;
			const endDate = manufacture.endDate ? manufacture.endDate as FirestoreTimestamp : null;
			if(endDate) {
				this.endDate = endDate?.seconds ? moment(endDate.seconds * 1000).toDate() : manufacture.endDate;
			} else {
				this.endDate = null;
			}
			this.startDate = startDate?.seconds ? moment(startDate.seconds * 1000).toDate() : manufacture.startDate;
			this.state = manufacture.state;

			this.employees = Manufacture.getArrayValues<ManufactureEmployeeData>(manufacture.employees);
			this.materials = Manufacture.getArrayValues<ManufactureStorageData>(manufacture.materials);
			this.rawMaterials = Manufacture.getArrayValues<ManufactureStorageData>(manufacture.rawMaterials);
			this.instruments = Manufacture.getArrayValues<ManufactureStorageData>(manufacture.instruments);
			this.machines = Manufacture.getArrayValues<ManufactureStorageData>(manufacture.machines);

			this.used = manufacture.used || false;
		}
	}

	public dataToSave() {
		return {
			name: this.name,
			quantity: this.quantity,
			machines: this.machines,
			instruments: this.instruments,
			rawMaterials: this.rawMaterials,
			materials: this.materials,
			employees: this.employees,
			endDate: this.endDate,
			startDate: this.startDate,
			state: this.state,
			used: this.used,
		};
	}

	private static getArrayValues<T>(data: Array<T>): Array<T> {
		if (!data || !data.length) return [];
		return data.filter(c => c);
	}
}

export class DBManufacture extends Manufacture {
	public id: string;
	constructor(manufacture: DBManufacture) {
		super(manufacture);
		this.id = manufacture.id;
	}
}
