import {FirestoreTimestamp, Model} from "../base.interface";
import moment from "moment";

export default class Employee implements Model {
    public id?: string;
	public names!: string;
	public start_date!: Date;
	public department!: string;
	public position!: string;
	public other!: string;

	public manufactureIds: Array<string> = [];
	public manufactureData: Array<ManufactureEmployeeData> = [];

	constructor(employee?: Employee) {
		if(employee) {
		    if(employee?.id) {
		        this.id = employee.id;
		    }
			this.names = employee.names;
			this.start_date = this.getDateValue(employee.start_date);
			this.department = employee.department;
			this.position = employee.position;
			this.other = employee.other || "";
			this.manufactureIds = employee.manufactureIds || [];
			this.manufactureData = employee.manufactureData || [];
		}
	}

	protected getDateValue(availableDate: Date | FirestoreTimestamp): Date {
		const date = availableDate as FirestoreTimestamp;
		return date?.seconds ? moment(date.seconds * 1000).toDate() : (availableDate as Date);
	}

	public totalWorkingHours() {
		if(!this.manufactureData) {
			return 0;
		}

		return this.manufactureData.reduce((previousValue, currentValue) => {
			return previousValue + (+currentValue.workingHours);
		}, 0)
	}

	dataToSave() {
		return {
			names: this.names,
			start_date: this.start_date,
			department: this.department,
			position: this.position,
			other: this.other,
			manufactureIds: this.manufactureIds,
			manufactureData: this.manufactureData,
		}
	}
}


export class ManufactureEmployeeData implements Model {
	/** manufacture id / employee id **/
	public id: string;
	/** manufacture name **/
	public name: string;

	/** employee hours **/
	public workingHours: number;

	/** employee name **/
	public employeeName: string;

	/** employee activity **/
	public activity: string;

	public constructor(data: any) {
		this.id = data.id;
		this.name = data.name;
		this.workingHours = data.workingHours;
		this.employeeName = data.employeeName;
		this.activity = data.activity;
	}

	dataToSave(): any {
		return {
			id: this.id,
			name: this.name,
			workingHours: this.workingHours,
			employeeName: this.employeeName,
			activity: this.activity,
		}
	}
}
