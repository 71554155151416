import BaseService from "../BaseService";
import {DbProduction as Production} from "../../models/Storage/Production";

class ProductionService extends BaseService {
	async getProductions(): Promise<Array<Production>> {
		const values =  await this.db.collection(this.collectionName).get();
		const prod: Array<Production> = [];
		values.forEach((val) => {
			const data = { id: val.id, ...val.data() };
			prod.push(new Production(data as Production));
		});
		return prod;
	}

	async find(id: string): Promise<Production> {
		const val =  await this.db.collection(this.collectionName).doc(id).get();
		const data = { id: val.id, ...val.data() };
		return new Production(data as Production);
	}
}

export default new ProductionService("production");
