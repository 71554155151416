import React, { useEffect, useRef, useState } from "react";
import { DbMaterial } from "../../../models/Storage/Material";
import { DbRawMaterial } from "../../../models/Storage/RawMaterial";
import { DbInstrument } from "../../../models/Storage/Instrument";
import { DbMachine } from "../../../models/Storage/Machine";
import MaterialsService from "../../../services/Storage/MaterialsService";
import RawMaterialsService from "../../../services/Storage/RawMaterialsService";
import InstrumentsService from "../../../services/Storage/InstrumentsService";
import MachinesService from "../../../services/Storage/MachinesService";
import ProductionService from "../../../services/Storage/ProductionService";
import { DbProduction } from "../../../models/Storage/Production";
import CardComponent from "../../shared/CardComponent";
import TableComponent, {Column} from "../../shared/TableComponent";
import {Box, FormControl, InputLabel, MenuItem, Select, Typography} from "@material-ui/core";
import {getConditionString, getUnitString} from "../../../common/consts/Enums";
import moment from "moment";
import { momentFormat } from "../../../common/Utils/FormUtils";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { RoutesUrl } from "../../../common/consts/Routes";
import EditIcon from "@material-ui/icons/Edit";
import {TableComponentToPrint} from "../../ComponentToPrint/TableComponent";
import {getColumns, getColumnsForExport, getDataForExport, WorkingTypes} from "../../../common/Utils/ActionUtils";
import {CardHeaderActions} from "../../shared/CardHeaderActions";

type SelectedType = "material" | "rawMaterial" | "production" | "instruments" | "machines";

interface Data {
	type: SelectedType;
	data: Array<WorkingTypes>;
	columns: Array<Column>;
	selectTitle: string;
}

export default function Stock() {
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [materials, setMaterials] = useState<Array<DbMaterial>>([]);
	const [rawMaterials, setRawMaterials] = useState<Array<DbRawMaterial>>([]);
	const [productions, setProductions] = useState<Array<DbProduction>>([]);
	const [instruments, setInstruments] = useState<Array<DbInstrument>>([]);
	const [machines, setMachines] = useState<Array<DbMachine>>([]);
	const [currentSelected, setCurrentSelected] = useState<Data | null>(null);
	let componentRef = useRef<TableComponentToPrint | null>(null);

	const columns: { [key in SelectedType]: Array<Column> } = {
		material: [
			{
				header: "Наименование",
				accessor: "name",
				cell: (val) => {
					return (
						<Typography variant="body1">{val}</Typography>
					)
				}
			},
			{
				header: "Количество",
				accessor: "quantity",
				cell: (val) => (
					<Typography variant="body1">{val}</Typography>
				),
			},
			{
				header: "Мерна единица",
				accessor: "unit",
				cell: (val) => {
					return (
						<Typography variant="body1">{getUnitString(val)}</Typography>
					)
				},
			},
			{
				header: "Дата на заприхождаване",
				accessor: "date",
				cell: (val) => (
					<Typography variant="body1">{moment(val).format(momentFormat)}</Typography>
				),
			},
			{
				header: "Цена, Без ДДС",
				accessor: "price",
				cell: (val) => (
					<Typography variant="body1">{val} лв.</Typography>
				),
			},
		],
		machines: [
			{
				header: "Наименование",
				accessor: "name",
				cell: (val) => {
					return (
						<Typography variant="body1">{val}</Typography>
					)
				}
			},
			{
				header: "Количество",
				accessor: "quantity",
				cell: (val) => (
					<Typography variant="body1">{val}</Typography>
				),
			},
			{
				header: "Състояние",
				accessor: "condition",
				cell: (val) => {
					return (
						<Typography variant="body1">{getConditionString(val)}</Typography>
					)
				},
			},
			{
				header: "Дата на заприхождаване",
				accessor: "date",
				cell: (val) => (
					<Typography variant="body1">{moment(val).format(momentFormat)}</Typography>
				),
			},
		],
		instruments: [],
		production: [],
		rawMaterial: [],
	}

	const data: Array<Data> = [
		{
			type: "material",
			data: materials,
			selectTitle: "Материали",
			columns: [
				...columns.material,
				{
					cell: ({ id }) => {
						return (
							<Box display="flex">
								<Link to={`${RoutesUrl.storage.editMaterials(id)}`}>
									<Typography color="primary"><EditIcon /></Typography>
								</Link>
							</Box>
						)
					},
					header: "Действие"
				},
			],
		},
		{
			type: "rawMaterial",
			data: rawMaterials,
			selectTitle: "Суровини",
			columns: [
				...columns.material,
				{
					cell: ({ id }) => {
						return (
							<Box display="flex">
								<Link to={`${RoutesUrl.storage.editRawMaterials(id)}`}>
									<Typography color="primary"><EditIcon /></Typography>
								</Link>
							</Box>
						)
					},
					header: "Действие"
				},
			],
		},
		{
			type: "production",
			data: productions,
			selectTitle: "Готова Продукция",
			columns: [
				...columns.material,
				{
					header: "Дистрибутор",
					accessor: "distributor",
					cell: (val) => (
						<Typography variant="body1">{val}</Typography>
					),
				},
			],
		},
		{
			type: "instruments",
			data: instruments,
			selectTitle: "Инструменти",
			columns: [
				...columns.machines,
				{
					cell: ({ id }) => {
						return (
							<Box display="flex">
								<Link to={`${RoutesUrl.storage.editInstruments(id)}`}>
									<Typography color="primary"><EditIcon /></Typography>
								</Link>
							</Box>
						)
					},
					header: "Действие"
				},
			],
		},
		{
			type: "machines",
			data: machines,
			selectTitle: "Машини",
			columns: [
				...columns.machines,
				{
					cell: ({ id }) => {
						return (
							<Box display="flex">
								<Link to={`${RoutesUrl.storage.editMachines(id)}`}>
									<Typography color="primary"><EditIcon /></Typography>
								</Link>
							</Box>
						)
					},
					header: "Действие"
				},
			],
		},
	];

	useEffect(() => {
		setIsLoading(true);
		const promises = [
			ProductionService.getProductions().then(values => setProductions(values)),
			MaterialsService.getMaterials().then(values => setMaterials(values)),
			RawMaterialsService.getRawMaterials().then(values => setRawMaterials(values)),
			InstrumentsService.getInstruments().then(values => setInstruments(values)),
			MachinesService.getMachines().then(values => setMachines(values)),
		];
		Promise.all(promises).finally(() => {
			setIsLoading(false);
		});
	}, []);

	const handleChange = (type: SelectedType) => {
		const element = data.find(item => item.type === type);
		if (element) {
			setCurrentSelected(element);
			return;
		}
		toast.error("Моля, опитайте отново");
	};

	const renderHeaderActions = () => {
		if (!currentSelected || !currentSelected.data.length) {
			return "";
		}

		const csvColumns = getColumnsForExport(currentSelected.columns);
		const csvData = getDataForExport(currentSelected.data);
		const csvFileName = `${currentSelected.type}_${+new Date()}.csv`;

		return (
			<CardHeaderActions
				csvHeaders={getColumns(csvColumns)}
				csvFileName={csvFileName}
				csvData={csvData}
				componentRef={componentRef}
			/>
		);
	}

	return (
		<CardComponent title={`Наличности`} isLoading={isLoading} headerActions={renderHeaderActions()}>
			<div>
				{
					currentSelected && currentSelected.data.length > 0 && (
						<div style={{ display: "none" }}>
							<TableComponentToPrint
								ref={componentRef}
								data={getDataForExport(currentSelected.data)}
								headers={getColumnsForExport(currentSelected.columns)}
								title={currentSelected.selectTitle}
							/>
						</div>
					)
				}
				<Box>
					<Box marginBottom="15px" maxWidth="300px" minWidth="150px" className="noPrint">
						<FormControl style={{ width: "100%" }}>
							<InputLabel>Тип</InputLabel>
							<Select
								fullWidth
								onChange={(e) => {
									handleChange(e.target.value as SelectedType)
								}}
								label={"Тип"}
								value={currentSelected?.type || ""}
							>
								{
									data.map((item) => (
										<MenuItem value={item.type} key={item.type}>{item.selectTitle}</MenuItem>
									))
								}
							</Select>
						</FormControl>
					</Box>
					{
						(currentSelected && currentSelected.data.length > 0) && (
							<TableComponent data={currentSelected.data} columns={currentSelected.columns} />
						)
					}
					{
						(!currentSelected) && (
							<Typography variant="body1">Моля, изберете типа който искате да прегледате</Typography>
						)
					}

					{
						(currentSelected && currentSelected.data.length === 0) && (
							<Typography variant="body1">{currentSelected.selectTitle} няма налични данни. Моля,
								въведете.</Typography>
						)
					}

				</Box>
			</div>
		</CardComponent>
	);
}
