import React, {useEffect, useState} from "react";
import {Box, MenuItem,} from "@material-ui/core";

import {Select, TextField} from 'mui-rff';
import DatePicker from "../../shared/DatePicker"
import {toast} from 'react-toastify';
import ProductionService from "../../../services/Storage/ProductionService";
import ProductionModel, {DbProduction} from "../../../models/Storage/Production";
import CardComponent from "../../shared/CardComponent";
import FormComponent from "../../shared/FormComponent";
import {getUnitString, States, Units} from "../../../common/consts/Enums";
import {DBManufacture} from "../../../models/manufacture/Manufacture";
import Loader from "../../shared/Loader";
import ManufactureService from "../../../services/Manufacture/ManufactureService";
import {useHistory, useParams} from "react-router-dom";
import {RoutesUrl} from "../../../common/consts/Routes";
import {FirestoreFilter} from "../../../services/BaseService";

export default function Production() {
	let {id} = useParams();
	const {push} = useHistory();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [manufactures, setManufactures] = useState<Array<DBManufacture>>([]);
	const [manufacture, setManufacture] = useState<DBManufacture | null>(null);

	const [production, setProduction] = useState<DbProduction | null>(null);

	useEffect(() => {
		setIsLoading(true);
		const filters: Array<FirestoreFilter> = [
			{
				value: States.Finished,
				opStr: "==",
				fieldPath: "state",
			},
		]
		ManufactureService.getWithArrayOfFilter<DBManufacture>(filters)
			.then(async (values) => {
				setManufactures(values);
				if (production) {
					await handleOptionSelect(production.manufacture);
				}
			})
			.finally(() => setIsLoading(false));
	}, []);

	useEffect(() => {
		if (id) {
			setIsLoading(true);
			ProductionService.find(id).then(async (val: DbProduction) => {
				setProduction(val);
				await handleOptionSelect(val.manufacture);
			}).finally(() => setIsLoading(false));
		}
	}, [id])

	const updateManufactureUsedState = async (state: boolean, id?: string) => {
		if (id) return await ManufactureService.update(id, {used: state});
	}

	const onSubmit = async (values: any): Promise<boolean | undefined> => {
		try {
			const data = {...values, name: manufacture?.name, quantity: manufacture?.quantity};
			if (id) {
				const man = manufactures.find((m) => m.id === data.manufacture);
				// prevent not existing manufacture when updating
				if (!data.name && man) {
					data.name = man?.name;
					data.quantity = man?.quantity
				}

				await ProductionService.findAndUpdate(id, data);
				if (production && man && production.name !== man.name) {
					await updateManufactureUsedState(false, production?.manufacture);
					await updateManufactureUsedState(true, man.id);
				}
				toast.success("Успешно редактиране");
				push(RoutesUrl.report.products);
				return;
			}

			await ProductionService.store(new ProductionModel(data));
			await updateManufactureUsedState(true, manufacture?.id);

			toast.success("Успешно добавяне на Готова продукция");
			return true;
		} catch (e) {
			toast.error("Нещо се обърка");
			console.error(e);
			return false;
		}
	};

	const handleOptionSelect = async (manufactureId?: string) => {
		if (!manufactureId) return;
		try {
			const manufacture = manufactures.find((man) => man.id === manufactureId);
			if (manufacture) setManufacture(manufacture);
		} catch (e) {
			toast.error("Нещо се обърка");
			console.error(e);
		}
	}

	const formFields = [
		{
			size: 6,
			field: (
				<DatePicker
					name="date"
					label="Дата на заприхождаване"
					required
				/>
			),
			id: "date",
			validations: {
				required: true,
			},
		},
		{
			size: 12,
			field: (
				<Select
					name="unit"
					label="Мерна единица"
					required
				>
					{
						Object.values(Units).filter((val: any) => Units[+val] !== undefined).map((val: any) => (
							<MenuItem value={val} key={val}>{getUnitString(val)}</MenuItem>
						))
					}
				</Select>
			),
			id: "unit",
			validations: {
				required: true,
			},
		},
		{
			size: 6,
			field: (
				<TextField
					label="Продажна цена без ДДС"
					name="price"
					margin="none"
					type={"number"}
					required
				/>
			),
			id: "price",
			validations: {
				required: true,
			},
		},
		{
			size: 6,
			field: (
				<TextField
					label="Дистрибутор"
					name="distributor"
					margin="none"
					type={"text"}
				/>
			),
			id: "distributor",
			validations: {
				required: false,
			},
		},
		{
			size: 6,
			field: (
				<Select
					name="manufacture"
					label="Продукция"
					required
					disabled={!manufactures.length}
				>
					{
						manufactures.map((manufacture) => (
							<MenuItem key={manufacture.id}
							          onClick={() => handleOptionSelect(manufacture?.id)}
							          disabled={manufacture.used}
							          value={manufacture.id}>
								{`${manufacture.name} - количество: ${manufacture.quantity}`}
							</MenuItem>
						))
					}
				</Select>
			),
			id: "manufacture",
			validations: {
				required: true,
			},
		},
	];

	return !isLoading ? (
		<Box>
			<CardComponent title={`Складиране на готова продукция`}>
				<FormComponent
					formFields={formFields}
					handleSubmit={(values) => {
						return onSubmit(values)
					}}
					initialValues={production || {}}
				/>
			</CardComponent>
		</Box>
	) : <Loader/>
}
