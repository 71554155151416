import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import CardComponent from "../../shared/CardComponent";
import { States } from "../../../common/consts/Enums";
import {DBManufacture} from "../../../models/manufacture/Manufacture";
import TableComponent, { Column } from "../../shared/TableComponent";
import {Box, Divider, Typography} from "@material-ui/core";
import moment from "moment";
import { momentFormat } from "../../../common/Utils/FormUtils";
import ManufactureService from "../../../services/Manufacture/ManufactureService";
import { RoutesUrl } from "../../../common/consts/Routes";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FilterHeader from "../shared/FilterHeader";
import { TableComponentToPrint } from "../../ComponentToPrint/TableComponent";
import {
	getColumns,
	getManufactureColumnsForExport,
	getManufactureDataForExport
} from "../../../common/Utils/ActionUtils";
import {CardHeaderActions} from "../../shared/CardHeaderActions";
import {FirestoreFilter} from "../../../services/BaseService";


const getTitle = (type: States) => {
	switch (type) {
		case States.Finished:
			return "Завършени Поръчки";
		case States.Planned:
			return "Планирано производство";
		default:
			return "Текущо Производство";
	}
}

export default function Manufactures() {
	const [title, setTitle] = useState<string>("");
	const [data, setData] = useState<Array<DBManufacture>>([]);

	const [filters, setFilters] = useState<Array<FirestoreFilter>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const { manufactureType } = useParams();

	let componentRef = useRef<TableComponentToPrint | null>(null);

	useEffect(() => {
		if (manufactureType !== undefined) {
			setTitle(getTitle(+manufactureType));
		}
	}, [manufactureType])

	useEffect(() => {
		setIsLoading(true);
		const defaultFilter: FirestoreFilter = {
			fieldPath: "state",
			opStr: "==",
			value: +manufactureType
		};
		ManufactureService.getWithArrayOfFilter<DBManufacture>([...filters, defaultFilter])
			.then(values => setData(values))
			.finally(() => setIsLoading(false));
	}, [manufactureType, filters])


	const columns: Array<Column> = [
		{
			header: "Наименование",
			accessor: "name",
			cell: (val) => {
				return (
					<Typography variant="body1">{val}</Typography>
				)
			}
		},
		{
			header: "Количество",
			accessor: "quantity",
			cell: (val) => (
				<Typography variant="body1">{val}</Typography>
			),
		},
		{
			header: "Дата на започване",
			accessor: "startDate",
			cell: (val) => (
				<Typography variant="body1">{moment(val).format(momentFormat)}</Typography>
			),
		},
		{
			header: "Дата на приключване",
			accessor: "endDate",
			cell: (val) => {
				if (!val) {
					return (<></>);
				}

				let date = val.hasOwnProperty("endDate") ? val.endDate : val;

				if (!date) {
					return (<></>);
				}

				return (
					<Typography variant="body1">{moment(date).format(momentFormat)}</Typography>
				)
			}
		},
		{
			cell: ({ id, used }) => {
				return (
					<Box display="flex">
						{
							!used && (
								<Link to={`${RoutesUrl.manufacture.edit(id)}`} title="Редакция">
									<Typography color="primary"><EditIcon /></Typography>
								</Link>
							)
						}

						{
							used && (
								<Link to={`${RoutesUrl.manufacture.edit(id)}`} title="Преглед">
									<Typography color="primary"><VisibilityIcon /></Typography>
								</Link>
							)
						}
					</Box>
				)
			},
			header: "Действие"
		},
	];

	const csvColumns = getManufactureColumnsForExport();

	const renderHeaderActions = () => {
		if (!data.length) return;

		const csvData = getManufactureDataForExport(data);
		const csvFileName = `Manufactures_${+new Date()}.csv`;

		return (
			<CardHeaderActions
				csvHeaders={getColumns(csvColumns)}
				csvFileName={csvFileName}
				csvData={csvData}
				componentRef={componentRef}
			/>
		);
	}

	return (
		<CardComponent title={title} isLoading={isLoading} headerActions={renderHeaderActions()}>
			<Box>
				{
					data.length > 0 && (
						<div style={{ display: "none" }}>
							<TableComponentToPrint
								ref={componentRef}
								data={getManufactureDataForExport(data)}
								headers={getManufactureColumnsForExport()}
								title={getTitle(+manufactureType)}
							/>
						</div>
					)
				}
				<FilterHeader onFilter={setFilters} />
				<Box marginBottom="15px" marginTop="15px">
					<Divider />
				</Box>
				<TableComponent data={data} columns={columns} />
			</Box>

		</CardComponent>
	);
}
