import {Model} from "../base.interface";
import {Units} from "../../common/consts/Enums";
import BaseStorageModel from "./BaseStorageModel";

export default class Material extends BaseStorageModel implements Model {
	public unit!: Units;
	public price!: number;

	constructor(material?: Material) {
		super(material);
		if(material) {
			this.unit = material.unit;
			this.price = material.price;
		}
	}

	dataToSave() {
		return {
			...this.getDefaultData(),
			price: +this.price,
			unit: this.unit,
		}
	}
}

export class DbMaterial extends Material {
	public id: string;

	constructor(material: DbMaterial) {
		super(material);
		this.id = material.id;
	}
}
