import {Select, TextField} from "mui-rff";
import {multipleValidators, validators} from "../../common/Utils/FormUtils";
import React from "react";
import {Box, MenuItem, Typography} from "@material-ui/core";
import {RemoveCircle} from "@material-ui/icons";
import DatePicker from "../shared/DatePicker";
import {getStateString, States} from "../../common/consts/Enums";

export const getDefaultQuantity = (name: string, data: Array<any>, validateField: any) => {
	return (
		<TextField
			label="Количество"
			name={`${name}.quantity`}
			type={"number"}
			required
			fieldProps={{
				validate: multipleValidators(
					validators.required,
					validators.mustBeNumber,
					validators.minNumber(0),
					validators.maxQuantityByAnotherField(data, validateField)
				)
			}}
		/>
	)
}

export const getDefaultSelect = (name: string, data: Array<any>, label: string, selected: Array<string> = []) => {
	return (
		<Select
			name={`${name}.id`}
			label={label}
			required
			fieldProps={{
				validate: validators.required
			}}
		>
			<MenuItem key={-1}>Моля, изберете</MenuItem>
			{
				data.map((item) => (
					<MenuItem disabled={selected.includes(item.id)} value={item.id}
					          key={item.id}>{item.name}</MenuItem>
				))
			}
		</Select>
	)
}

export const dynamicHeader = (title: string, index: number, onClick: any) => {
	return (
		<Box display="flex" justifyContent="space-between">
			<Typography variant="body1">{title} #{index + 1}</Typography>
			<RemoveCircle color="error" style={{cursor: "pointer"}}
			              onClick={onClick}/>
		</Box>
	)
}

export const fields = [
	{
		size: 4,
		field: (
			<TextField
				label="Наименование"
				name="name"
				margin="none"
				required
			/>
		),
		id: "name",
		validations: {
			required: true,
		},
	},
	{
		size: 4,
		field: (
			<TextField
				label="Количество"
				name="quantity"
				margin="none"
				type={"number"}
				required
			/>
		),
		id: "quantity",
		validations: {
			required: true,
		},
	},
	{
		size: 4,
		field: (
			<DatePicker
				name="startDate"
				label="Дата на започване"
				required
			/>
		),
		id: "startDate",
		validations: {
			required: true,
		},
	},
	{
		size: 4,
		field: (
			<DatePicker
				name="endDate"
				label="Дата на приключване"
				required={false}
			/>
		),
		id: "endDate",
		validations: {
			required: false,
		},
	},
	{
		size: 4,
		field: (
			<Select
				name="state"
				label="Етапност на изпълнение"
				required
			>
				<MenuItem selected value={States.Planned}>{getStateString(States.Planned)}</MenuItem>
				<MenuItem value={States.Started}>{getStateString(States.Started)}</MenuItem>
				<MenuItem value={States.Finished}>{getStateString(States.Finished)}</MenuItem>
			</Select>
		),
		id: "state",
		validations: {
			required: true,
		},
	},
];
