import React, {useEffect, useRef, useState} from "react";
import CardComponent from "../../shared/CardComponent";
import Manufacture, {DBManufacture} from "../../../models/manufacture/Manufacture";
import {Box, Divider} from "@material-ui/core";
import ManufactureService from "../../../services/Manufacture/ManufactureService";
import Row from "./Row";
import FilterHeader from "../shared/FilterHeader";
import {
	getColumns,
	getManufactureColumnsForExport,
	getManufactureDataForExport
} from "../../../common/Utils/ActionUtils";
import {TableComponentToPrint} from "../../ComponentToPrint/TableComponent";
import {CardHeaderActions} from "../../shared/CardHeaderActions";
import TableComponent, {Column} from "../../shared/TableComponent";
import {FirestoreFilter} from "../../../services/BaseService";


export default function MachinesInstruments() {
	const [data, setData] = useState<Array<DBManufacture>>([]);
	const [filters, setFilters] = useState<Array<FirestoreFilter>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const columns = getManufactureColumnsForExport();
	const tableColumns: Array<Column> = columns.map((col) => {
		return {
			header: col.name,
			cell: () => <></>
		}
	});

	tableColumns.unshift({
		header: "",
		cell: () => <></>
	});

	tableColumns.push({
		header: "Действие",
		cell: () => <></>
	});

	useEffect(() => {
		setIsLoading(true);
		ManufactureService.getWithArrayOfFilter<DBManufacture>([...filters])
			.then(values => setData(values))
			.finally(() => setIsLoading(false));
	}, [filters])

	let componentRef = useRef<TableComponentToPrint | null>(null);

	const renderHeaderActions = () => {
		if (!data.length) return;

		const csvData = getManufactureDataForExport(data);
		const csvFileName = `MachinesInstruments_${+new Date()}.csv`;

		return (
			<CardHeaderActions
				csvHeaders={getColumns(columns)}
				csvFileName={csvFileName}
				csvData={csvData}
				componentRef={componentRef}
			/>
		);
	}

	return (
		<CardComponent
			title={"Производствена програма – инструменти и машини"}
			isLoading={isLoading}
			headerActions={renderHeaderActions()}
		>
			<Box>
				{
					data.length > 0 && (
						<div style={{display: "none"}}>
							<TableComponentToPrint
								ref={componentRef}
								data={getManufactureDataForExport(data)}
								headers={getManufactureColumnsForExport()}
								title={"Производствена програма – инструменти и машини"}
							/>
						</div>
					)
				}
				<FilterHeader onFilter={setFilters}/>
				<Box marginBottom="15px" marginTop="15px">
					<Divider/>
				</Box>
				<TableComponent data={data} columns={tableColumns} rowElement={(row, index) => <Row key={index} row={row} />} />
			</Box>
		</CardComponent>
	);
}
