export const validationMessages = {
	required: () => "Полето е задължително",
	minLength: (min: number) => {
		return `Минимални знаци за полето ${min}`;
	},
	maxLength: (max: number) => {
		return `Максимални знаци за полето ${max}`
	},
	number: () => "Трябва да въведете само числа",
	minNumber: (min: number) => `Въведете число по-голямо от ${min}`,
	maxNumber: (max: number) =>  `Въведете число по-малко от ${max}`
};

export const validators = {
	required: (value: any) => (value ? undefined : validationMessages.required()),
	mustBeNumber: (value: any) => (isNaN(value) ? validationMessages.number() : undefined),
	minNumber: (min: number) => (value: any) =>
		isNaN(value) || value >= min ? undefined : `Въведете число по-голямо от ${min}`,
	maxQuantityByAnotherField: (data: Array<any>, selectedId: string, key  = "quantity") => (value: any) => {
		if(!selectedId) return undefined;
		if(value && selectedId) {
			const item = data.find(elem => elem.id === selectedId);
			if(!item) return undefined;
			return +value <= +item[key] || isNaN(value) ? undefined : validationMessages.maxNumber(item[key]);
		}
		return undefined;
	},
};

export const multipleValidators = (...validators: Array<any>) => (value: any) =>
	validators ? validators.reduce((error: any, validator) => error || validator(value), undefined) : undefined;


export const validateForm = (values: any, fields: any) => {
	let formErrors: any = {};
	const messages = validationMessages;
	for(const field of fields) {
		const validations = field.validations;
		const val = values[field.id] || null;

		if(validations.required && !val) {
			formErrors[field.id] = messages.required();
			continue;
		}

		if(validations?.minLength && val.length < validations?.minLength) {
			formErrors[field.id] = messages.minLength(validations.minLength);
			continue;
		}

		if(validations?.maxLength && val.length > validations?.maxLength) {
			formErrors[field.id] = messages.maxLength(validations.maxLength);
		}
	}

	return formErrors;
}

export const dateFormat ="dd/MM/yyyy HH:mm";
export const momentFormat = "DD/MM/YYYY HH:mm";
