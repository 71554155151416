import {States} from "./Enums";

export const RoutesParams = {
	id: 'id',
	manufactureType: 'manufactureType',
};

const getParam = (param: string) => `:${param}`;

export const RoutesUrl = {
	welcome: '/',
	dashboard: "/dashboard/",
	storage: {
        link: "/skladovo-stopanstvo",
        materials: "/dashboard/materials/novo",
        editMaterials: (id?: string) => `/dashboard/materials/redaktirane/${id || getParam(RoutesParams.id)}`,
		rawMaterials: "/dashboard/surovini/novo",
		editRawMaterials: (id?: string) => `/dashboard/surovini/redaktirane/${id || getParam(RoutesParams.id)}`,
		production: "/dashboard/gotova-produkciya/create",
		editProduction: (id?: string) => `/dashboard/gotova-produkciya/redaktirane/${id || getParam(RoutesParams.id)}`,
		instruments: "/dashboard/nalichnost-na-instrumenti/novo",
		editInstruments: (id?: string) => `/dashboard/nalichnost-na-instrumenti/redaktirane/${id || getParam(RoutesParams.id)}`,
		machines: "/dashboard/mashini-tehnika/novo",
		editMachines: (id?: string) => `/dashboard/mashini-tehnika/redaktirane/${id || getParam(RoutesParams.id)}`,
    },
	staff: {
		list: "/dashboard/personal/spisak",
		create: "/dashboard/personal/suzdavane",
		edit: (id?: string) => `/dashboard/personal/edit/${id || getParam(RoutesParams.id)}`,
	},
	manufacture: {
		list: "/dasboard/proizvodstvo/spisak",
		"new": "/dasboard/proizvodstvo/novo",
		edit: (id?: string) => `/dashboard/prozvodstvo/redaktirane/${id || getParam(RoutesParams.id)}`,
	},
	report: {
		stock: "/dashboard/reports/nalichnosti",
		manufactures: (type?: States) => `/dashboard/reports/manufactures/${type || getParam(RoutesParams.manufactureType)}`,
		storageMovements: "/dashboard/reports/skladovi-dvijeniya",
		staff: "/dashboard/reports/sluzhiteli",
		machinesInstruments: "/dashboard/reports/mashini-instrumenti",
		products: "/dashboard/reports/produkti"
	}
};
