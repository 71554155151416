import React, {useEffect, useState} from "react";
import {Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select} from "@material-ui/core";
import {InlineDatePicker} from "../../shared/DatePicker";
import {Clear, Search} from "@material-ui/icons";
import firebase from "../../firebase";
import {useLocation} from "react-router-dom";
import {FirestoreFilter} from "../../../services/BaseService";

type Props = {
	onFilter(filters: Array<FirestoreFilter>): void;
	filters?: Array<FirestoreFilter>;
}

export default function FilterHeader(props: Props) {
	const { onFilter } = props;
	const [filterBy, setFilterBy] = useState("startDate");
	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);

	const location = useLocation();

	useEffect(() => {
		setStartDate(null);
		setEndDate(null);
	}, [location])

	const handleSearch = () => {
		const fil: Array<FirestoreFilter> = [];
		if (startDate) {
			fil.push({
				value: firebase.firestore().Timestamp.fromDate(startDate),
				opStr: ">",
				fieldPath: filterBy,
			})
		}

		if (endDate) {
			fil.push({
				value: firebase.firestore().Timestamp.fromDate(endDate),
				opStr: "<",
				fieldPath: filterBy
			})
		}
		onFilter(fil);
	}

	const clearSearch = () => {
		setStartDate(null);
		setEndDate(null);
		onFilter([]);
	}

	const filtersBy = [
		{
			label: "Дата на започване",
			value: "startDate"
		},
		{
			label: "Дата на приключване",
			value: "endDate",
		}
	]

	return (
		<Grid container style={{ flexGrow: 1 }} spacing={2} alignItems="center">
			<Grid item xs={6}>
				<Grid container style={{ flexGrow: 1 }} spacing={2}>
					<Grid item xs={4}>
						<FormControl style={{ width: "100%" }}>
							<InputLabel>Филтрирай по:</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={filterBy}
								onChange={(e) => setFilterBy(e?.target?.value as string)}
							>
								{
									filtersBy.map((item) => {
										return (
											<MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
										)
									})
								}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={4}>
						<InlineDatePicker label="Начална дата" value={startDate} onChange={setStartDate} />
					</Grid>
					<Grid item xs={4}>
						<InlineDatePicker label="Крайна дата" value={endDate} onChange={setEndDate} />
					</Grid>
				</Grid>
			</Grid>

			<Grid item xs={6}>
				<Grid container style={{ flexGrow: 1 }} justify="flex-end" alignItems="center">
					{
						(startDate || endDate) && (
							<Grid item xs={1}>
								<IconButton color="primary" onClick={clearSearch} title="Премахни търсенето">
									<Clear />
								</IconButton>
							</Grid>
						)
					}
					<Grid item xs={3}>
						<Button startIcon={<Search />} fullWidth variant="contained" color="primary" onClick={handleSearch} disabled={!startDate && !endDate}>
							Търсене
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}
