import BaseService, {CollectionResponse, FirestoreFilter} from "../BaseService";
import Employee from "../../models/Staff/Employee";
import firebase from "firebase/app";

class EmployeeService extends BaseService {
	async getEmployees(): Promise<Array<Employee>> {
		const values =  await this.db.collection(this.collectionName).get();
		const empl: Array<Employee> = [];
		values.forEach((val) => {
			const data = { id: val.id, ...val.data() };
			empl.push(new Employee(data as Employee));
		});
		return empl;
	}

	async find(id: string): Promise<Employee> {
		const val =  await this.db.collection(this.collectionName).doc(id).get();
		const data = { id: val.id, ...val.data() };
		return new Employee(data as Employee);
	}
}

export default new EmployeeService("staff");
