import BaseService from "../BaseService";
import Manufacture, {DBManufacture} from "../../models/manufacture/Manufacture";


class ManufactureService extends BaseService {
	async get(): Promise<Array<DBManufacture>> {
		const values =  await this.db.collection(this.collectionName).get();
		return this.returnData<DBManufacture>(values);
	}

	async find(id: string): Promise<DBManufacture> {
		const val =  await this.db.collection(this.collectionName).doc(id).get();
		const data = { id: val.id, ...val.data() } as DBManufacture;
		return new DBManufacture(data);
	}

	async update(id: string, data: Manufacture | { used: boolean, dataToSave?: undefined }) {
		try {
			const doc =  await this.db.collection(this.collectionName).doc(id);
			if(doc) {
				const update = data.dataToSave !== undefined ? data.dataToSave() : data;
				return await doc.update(update);
			}
		} catch(e) {
			console.error(e);
			throw e;
		}
	}

	async updateUsed(id: string, data: { used: boolean }) {
		try {
			const doc =  await this.db.collection(this.collectionName).doc(id);
			if(doc) {
				return await doc.update(data);
			}
		} catch(e) {
			console.error(e);
			throw e;
		}
	}
}

export default new ManufactureService("manufactures");
