import {Model} from "../base.interface";
import {Condition} from "../../common/consts/Enums";
import BaseStorageModel from "./BaseStorageModel";

export default class Machine extends BaseStorageModel implements Model {
	public condition!: Condition;

	constructor(machine?: Machine) {
		super(machine);
		if(machine) {
			this.condition = machine.condition;
		}
	}

	dataToSave() {
		return {
			...this.getDefaultData(),
			condition: this.condition,
		}
	}
}

export class DbMachine extends Machine {
	public id: string;

	constructor(machine: DbMachine) {
		super(machine);
		this.id = machine.id;
	}
}
