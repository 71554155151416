import DateFnsUtils from "@date-io/date-fns";
import bgLocale from "date-fns/locale/bg";
import {DateTimePicker, DateTimePickerProps} from "mui-rff";
import React from "react";
import {dateFormat} from "../../common/Utils/FormUtils";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import { DateTimePicker as DateTimePickerMaterial, DateTimePickerProps as DateTimePickerPropsMaterial } from "@material-ui/pickers";
import {IconButton, InputAdornment} from "@material-ui/core";
import {Today} from "@material-ui/icons";

type Props = {
	name: string;
	label: string;
	format?: string;
	required?: boolean;
} & DateTimePickerProps;

export default function DatePicker(props: Props) {
	const { name, label, format = dateFormat, required} = props;
	return (
		<DateTimePicker
			dateFunsUtils={DateFnsUtils}
			locale={bgLocale}
			name={name}
			label={label}
			autoOk
			format={format}
			ampm={false}
			required={required}
		/>
	)
}


type InlineProps = {
	value: Date | null;
	onChange: any;
	label: string;
} & DateTimePickerPropsMaterial;

export function InlineDatePicker(props: InlineProps) {
	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={bgLocale}>
			<DateTimePickerMaterial
				variant="inline"
				label={props.label}
				fullWidth
				autoOk
				value={props.value}
				format={dateFormat}
				onChange={props.onChange}
				ampm={false}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton>
								<Today />
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
		</MuiPickersUtilsProvider>
	)
}
