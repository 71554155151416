import React, { useEffect, useState } from "react";
import Material, { DbMaterial } from "../../../models/Storage/Material";
import RawMaterial, { DbRawMaterial } from "../../../models/Storage/RawMaterial";
import MaterialsService from "../../../services/Storage/MaterialsService";
import RawMaterialsService from "../../../services/Storage/RawMaterialsService";
import ProductionService from "../../../services/Storage/ProductionService";
import Production, { DbProduction } from "../../../models/Storage/Production";
import CardComponent from "../../shared/CardComponent";
import TableComponent, { Column } from "../../shared/TableComponent";
import { MenuItem, Select, Typography, Box, FormControl, InputLabel } from "@material-ui/core";
import { getUnitString } from "../../../common/consts/Enums";
import moment from "moment";
import { momentFormat } from "../../../common/Utils/FormUtils";
import { toast } from "react-toastify";
import { ManufactureStorageData } from "../../../models/Storage/BaseStorageModel";
import { Link } from "react-router-dom";
import { RoutesUrl } from "../../../common/consts/Routes";
import EditIcon from "@material-ui/icons/Edit";

type SelectedType = "material" | "rawMaterial" | "production";
type WorkingTypes = Material | RawMaterial | Production;

interface Data {
	type: SelectedType;
	data: Array<WorkingTypes>;
	columns: Array<Column>;
	selectTitle: string;
}

export default function Products() {
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [materials, setMaterials] = useState<Array<DbMaterial>>([]);
	const [rawMaterials, setRawMaterials] = useState<Array<DbRawMaterial>>([]);
	const [productions, setProductions] = useState<Array<DbProduction>>([]);
	const [currentSelected, setCurrentSelected] = useState<Data | null>(null);

	const prependColumns: Array<Column> = [
		{
			header: "Наименование",
			accessor: "name",
			cell: (val) => {
				return (
					<Typography variant="body1">{val}</Typography>
				)
			}
		},
		{
			header: "Количество",
			accessor: "quantity",
			cell: (val) => (
				<Typography variant="body1">{val}</Typography>
			),
		},
	];

	const appendColumns: Array<Column> = [
		{
			header: "Мерна единица",
			accessor: "unit",
			cell: (val) => {
				return (
					<Typography variant="body1">{getUnitString(val)}</Typography>
				)
			},
		},
		{
			header: "Дата на заприхождаване",
			accessor: "date",
			cell: (val) => (
				<Typography variant="body1">{moment(val).format(momentFormat)}</Typography>
			),
		},
		{
			header: "Цена, Без ДДС",
			accessor: "price",
			cell: (val) => (
				<Typography variant="body1">{val} лв.</Typography>
			),
		},
	];

	const storageColumns: Array<Column> = [
		{
			header: "Употребено в производство",
			cell: (val) => val.manufactureData ? (
				<Typography variant="body1">{
					val.manufactureData.reduce((oldVal: number, currentVal: ManufactureStorageData) => oldVal + (+currentVal.quantity), 0)
				}</Typography>
			) : <></>,
		},
	]

	const data: Array<Data> = [
		{
			type: "material",
			data: materials,
			selectTitle: "Материали",
			columns: [
				...prependColumns,
				...storageColumns,
				...appendColumns,
				{
					cell: ({ id }) => {
						return (
							<Box display="flex">
								<Link to={`${RoutesUrl.storage.editMaterials(id)}`}>
									<Typography color="primary"><EditIcon /></Typography>
								</Link>
							</Box>
						)
					},
					header: "Действие"
				},
			],
		},
		{
			type: "rawMaterial",
			data: rawMaterials,
			selectTitle: "Суровини",
			columns: [
				...prependColumns,
				...storageColumns,
				...appendColumns,
				{
					cell: ({ id }) => {
						return (
							<Box display="flex">
								<Link to={`${RoutesUrl.storage.editRawMaterials(id)}`}>
									<Typography color="primary"><EditIcon /></Typography>
								</Link>
							</Box>
						)
					},
					header: "Действие"
				},
			]
		},
		{
			type: "production",
			data: productions,
			selectTitle: "Готова Продукция",
			columns: [
				...prependColumns,
				...appendColumns,
				{
					header: "Дистрибутор",
					accessor: "distributor",
					cell: (val) => (
						<Typography variant="body1">{val}</Typography>
					),
				},
				{
					cell: ({ id }) => {
						return (
							<Box display="flex">
								<Link to={`${RoutesUrl.storage.editProduction(id)}`}>
									<Typography color="primary"><EditIcon /></Typography>
								</Link>
							</Box>
						)
					},
					header: "Действие"
				},
			],
		},
	];

	useEffect(() => {
		setIsLoading(true);
		const promises = [
			ProductionService.getProductions().then(values => setProductions(values)),
			MaterialsService.getMaterials().then(values => setMaterials(values)),
			RawMaterialsService.getRawMaterials().then(values => setRawMaterials(values)),
		];
		Promise.all(promises).finally(() => {
			setIsLoading(false);
		});
	}, []);

	const handleChange = (type: SelectedType) => {
		const element = data.find(item => item.type === type);
		if (element) {
			setCurrentSelected(element);
			return;
		}
		toast.error("Моля, опитайте отново");
	};

	return (
		<CardComponent title={`Продукти / Суровини`} isLoading={isLoading}>
			<Box>
				<Box marginBottom="15px" maxWidth="300px" minWidth="150px">
					<FormControl style={{ width: "100%" }}>
						<InputLabel>Тип</InputLabel>
						<Select
							fullWidth
							onChange={(e) => { handleChange(e.target.value as SelectedType) }}
							label={"Тип"}
							value={currentSelected?.type || ""}
						>
							{
								data.map((item) => (
									<MenuItem value={item.type} key={item.type}>{item.selectTitle}</MenuItem>
								))
							}
						</Select>
					</FormControl>
				</Box>
				{
					(currentSelected && currentSelected.data.length > 0) && (
						<TableComponent data={currentSelected.data} columns={currentSelected.columns} />
					)
				}
				{
					(!currentSelected) && (
						<Typography variant="body1">Моля, изберете типа който искате да прегледате</Typography>
					)
				}

				{
					(currentSelected && currentSelected.data.length === 0) && (
						<Typography variant="body1">{currentSelected.selectTitle} няма налични данни. Моля, въведете.</Typography>
					)
				}
			</Box>
		</CardComponent>
	);
}
