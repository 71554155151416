import {Model} from "../base.interface";
import {Units} from "../../common/consts/Enums";
import BaseStorageModel from "./BaseStorageModel";

export default class Production extends BaseStorageModel implements Model {
	public unit!: Units;
	public price!: number;
	public manufacture!: string;
	public distributor!: string;

	constructor(production?: Production) {
		super(production);
		if(production) {
			this.unit = production.unit;
			this.price = production.price;
			this.manufacture = production.manufacture;
			this.distributor = production.distributor
		}
	}

	dataToSave() {
		return {
			...this.getDefaultData(),
			unit: this.unit,
			price: this.price,
			manufacture: this.manufacture,
			distributor: this.distributor,
		}
	}
}

export class DbProduction extends Production {
	public id: string;

	constructor(production: DbProduction) {
		super(production);
		this.id = production.id;
	}
}
