import React, {useEffect, useState} from "react";
import {
	Box,
} from "@material-ui/core";
import { TextField} from 'mui-rff';
import DatePicker from "../shared/DatePicker"
import { toast } from 'react-toastify';
import EmployeeModel from "../../models/Staff/Employee";
import EmployeeService from "../../services/Staff/EmployeeService";
import {useHistory, useParams} from "react-router-dom";
import CardComponent from "../shared/CardComponent";
import {RoutesUrl} from "../../common/consts/Routes";
import FormComponent from "../shared/FormComponent";
import ManufactureService from "../../services/Manufacture/ManufactureService";
import {FirestoreFilter} from "../../services/BaseService";
import {DBManufacture} from "../../models/manufacture/Manufacture";

export default function Employee() {
	let { id } = useParams();
	const { push } = useHistory();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [employee, setEmployee] = useState<EmployeeModel | null>(null);

	useEffect(() => {
		if(id) {
			setIsLoading(true);
			EmployeeService.find(id).then((val: EmployeeModel) => {
				setEmployee(val);
			}).finally(() => setIsLoading(false));
		}
	}, [id])

	const onSubmit = async (values: EmployeeModel): Promise<boolean | undefined>  => {
		try {
			if(id) {
				await EmployeeService.findAndUpdate(id, new EmployeeModel(values).dataToSave());
				if(employee && values.names !== employee.names && employee.manufactureIds.length > 0) {
					const firestoreFilter: FirestoreFilter = {
						fieldPath: "name",
						opStr: "in",
						value: employee.manufactureData.map((man) => man.name),
					}
					const manufactures = await ManufactureService.getWithArrayOfFilter<DBManufacture>([firestoreFilter]);
					if(manufactures.length) {
						for(const manufacture of manufactures) {
							const manufactureEmployee = manufacture.employees.find((manEmp) => manEmp.id === id);
							if(manufactureEmployee) {
								manufactureEmployee.employeeName = values.names;
								if(manufacture.id) {
									await ManufactureService.findAndUpdate(manufacture.id, {employees: manufacture.employees});
								}
							}
						}
					}
				}

				toast.success("Успешно редактиране");
				push(RoutesUrl.staff.list);
				return;
			}

			await EmployeeService.store(new EmployeeModel(values));
			toast.success("Успешно добавяне");
			return true;
		} catch(e) {
			toast.error("Нещо се обърка");
			console.error(e);
		}
	};

	const formFields = [
		{
			size: 6,
			field: (
				<TextField
					label="Имена"
					name="names"
					margin="none"
					required
				/>
			),
			id: "names",
			validations: {
				required: true,
			},
		},
		{
			size: 6,
			field: (
				<DatePicker
					name="start_date"
					label="Дата на постъпване"
					required
				/>
			),
			id: "start_date",
			validations: {
				required: true,
			},
		},
		{
			size: 6,
			field: (
				<TextField
					label="Отдел"
					name="department"
					required
				/>
			),
			id: "department",
			validations: {
				required: true,
			},
		},
		{
			size: 6,
			field: (
				<TextField
					label="Позиция"
					name="position"
					required
				/>
			),
			id: "position",
			validations: {
				required: true,
			},
		},
		{
			size: 6,
			field: (
				<TextField
					label="Други"
					name="other"
					multiline
					rows={4}
				/>
			),
			id: "other",
			validations: {
				required: false,
			},
		},
	];
	const title = id ? 'Редактиране на персонал' : "Добавяне на персонал";
	return (
		<Box>
			<CardComponent title={title} isLoading={isLoading}>
				<FormComponent formFields={formFields} handleSubmit={(values) => { return onSubmit(values) }} initialValues={id ? employee : {}} />
			</CardComponent>
		</Box>
	);
}
