import React from "react";
import {
	createStyles, Box, Grid, Theme, Divider, Hidden, Typography,
} from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
	RouteComponentProps, withRouter, Switch, Route,
} from "react-router-dom";
import SideMenu from "../shared/SideMenu";
import AccountMenu from "../shared/AccountMenu";
import Machines from "../Storage/Machines/Machines";
import Materials from "../Storage/Мaterials/Materials";
import { RoutesUrl } from "../../common/consts/Routes";
import Production from "../Storage/Production/Production";
import Instruments from "../Storage/Instruments/Instruments";
import Employee from "../Staff/Employee";
import RawMaterials from "../Storage/RawMaterials/RawMaterials";

import NewManufacture from "../Manufacture/CreateEdit";
import ManufactureList from "../Manufacture/List";
import Stock from "../Reports/Stock/Stock";
import ReportManufactures from "../Reports/Manufactures/Manufactures";
import StorageMovementsReport from "../Reports/StorageMovements/StorageMovements";
import StaffReport from "../Reports/Staff/Staff";
import MachinesInstrumentsReport from "../Reports/MachinesInstruments/MachinesInstruments";
import ProductsReport from "../Reports/Products/Products";
import logo from "../../assets/logo.jpg";

const styles = ({ palette, breakpoints }: Theme) => {
	return createStyles({
		"@global": {
			"html, body, #root": {
				width: "100%",
				height: "100%",
			},
		},
		body: {
			height: "100%",
			backgroundColor: palette.background.default,
			[breakpoints.down("sm")]: {
				display: "block",
			},
			[breakpoints.up("sm")]: {
				"> *": {
					minHeight: "100%",
				},
			},
		},
		paper: {
			backgroundColor: palette.background.paper,
			padding: "1px",
			minWidth: 260,
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between"
		},
		content: {
			flex: 1,
			overflow: "auto",
		},
		default: {
			background: palette.background.default,
			padding: "1px 0px"
		}
	});
};

// eslint-disable-next-line @typescript-eslint/naming-convention
function Dashboard(props: RouteComponentProps & WithStyles): JSX.Element {
	const { classes } = props;

	return (
		<Grid container className={classes.body} wrap="nowrap">
			<Box className={`${classes.paper} noPrint`}>
				<Box>
					<Box className={classes.default}>
						<img src={logo} alt="logo" style={{
							height: 70,
							margin: "10px auto 20px auto",
							display: "block",
						}} />
					</Box>
					<AccountMenu />
					<Hidden smDown>
						<Box><Divider variant="fullWidth" color="primary" /></Box>
						<SideMenu />
					</Hidden>
				</Box>
				<Box padding={2} className={classes.info}>
					<Typography variant="body2">Produced by: MOVEN SOFT LTD.<br />
					Serial Number: MVS-0016575845
					</Typography>
				</Box>
			</Box>
			<Box padding="17px 20px" overflow="auto" flex="auto" id="printarea">
				<Switch>
					<Route path={RoutesUrl.storage.materials} component={Materials} />
					<Route path={RoutesUrl.storage.editMaterials()} component={Materials} />
					<Route path={RoutesUrl.storage.rawMaterials} component={RawMaterials} />
					<Route path={RoutesUrl.storage.editRawMaterials()} component={RawMaterials} />
					<Route path={RoutesUrl.storage.production} component={Production} />
					<Route path={RoutesUrl.storage.editProduction()} component={Production} />
					<Route path={RoutesUrl.storage.instruments} component={Instruments} />
					<Route path={RoutesUrl.storage.editInstruments()} component={Instruments} />
					<Route path={RoutesUrl.storage.machines} component={Machines} />
					<Route path={RoutesUrl.storage.editMachines()} component={Machines} />
					<Route path={RoutesUrl.staff.list} component={StaffReport} />
					<Route path={RoutesUrl.staff.create} component={Employee} />
					<Route path={RoutesUrl.staff.edit()} component={Employee} />
					<Route path={RoutesUrl.manufacture.list} component={ManufactureList} />
					<Route path={RoutesUrl.manufacture.new} component={NewManufacture} />
					<Route path={RoutesUrl.manufacture.edit()} component={NewManufacture} />
					<Route path={RoutesUrl.report.stock} component={Stock} />
					<Route path={RoutesUrl.report.manufactures()} component={ReportManufactures} />
					<Route path={RoutesUrl.report.storageMovements} component={StorageMovementsReport} />
					<Route path={RoutesUrl.report.staff} component={StaffReport} />
					<Route path={RoutesUrl.report.machinesInstruments} component={MachinesInstrumentsReport} />
					<Route path={RoutesUrl.report.products} component={ProductsReport} />
				</Switch>
			</Box>
		</Grid>
	);
}

export default withRouter(withStyles(styles)(Dashboard));
