import React, {useEffect, useState} from "react";
import CardComponent from "../shared/CardComponent";
import {Form} from "react-final-form";
import arrayMutators from 'final-form-arrays';
import {FieldArray} from 'react-final-form-arrays'
import {TextField} from "mui-rff";
import {Box, Button, Divider, Grid, GridSize, Typography, createStyles} from "@material-ui/core";
import {multipleValidators, validateForm, validators} from "../../common/Utils/FormUtils";
import Employee from "../../models/Staff/Employee";
import EmployeeService from "../../services/Staff/EmployeeService";
import {DbMaterial} from "../../models/Storage/Material";
import MaterialsService from "../../services/Storage/MaterialsService";
import RawMaterialsService from "../../services/Storage/RawMaterialsService";
import {DbRawMaterial} from "../../models/Storage/RawMaterial";
import {DbInstrument} from "../../models/Storage/Instrument";
import InstrumentsService from "../../services/Storage/InstrumentsService";
import {DbMachine} from "../../models/Storage/Machine";
import MachinesService from "../../services/Storage/MachinesService";
import Manufacture from "../../models/manufacture/Manufacture";
import {toast} from "react-toastify";
import ManufactureService from "../../services/Manufacture/ManufactureService";
import {RouteComponentProps, useHistory, useParams, withRouter} from "react-router-dom";
import {dynamicHeader, fields, getDefaultQuantity, getDefaultSelect} from "./utils";
import ManufactureHandler, {HandlerData, HandlerDataValues, ManufactureKeys} from "./Handler";
import {RoutesUrl} from "../../common/consts/Routes";
import {Theme} from "@material-ui/core/styles";
import withStyles, {WithStyles} from "@material-ui/core/styles/withStyles";

const styles = ({ palette }: Theme) => {
	return createStyles({
		form: {
			width: "100%", // Fix IE 11 issue.
		},
		link: {
			color: palette.primary.main,
		},
		boxTitle: {
			background: palette.primary.main,
			color: palette.primary.contrastText,
			padding: "8px 15px",
			maxWidth: "40%",
			flexBasis: "40%",
		},
		grid: {
			padding: 15,
		},
	});
};

function NewManufacture(props: RouteComponentProps & WithStyles) {
	let {id} = useParams();
	let {push} = useHistory();
	const { classes } = props;
	const [employees, setEmployees] = useState<Array<Employee>>([]);
	const [materials, setMaterials] = useState<Array<DbMaterial>>([]);
	const [rawMaterials, setRawMaterials] = useState<Array<DbRawMaterial>>([]);
	const [instruments, setInstruments] = useState<Array<DbInstrument>>([]);
	const [machines, setMachines] = useState<Array<DbMachine>>([]);
	const [initialValues, setInitialValues] = useState<Manufacture>(new Manufacture());
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const loadStorageInformation = () => {
		setIsLoading(true);
		const promises = [
			EmployeeService.getEmployees().then((values) => setEmployees(values)),
			MaterialsService.getMaterials().then(values => setMaterials(values)),
			RawMaterialsService.getRawMaterials().then(values => setRawMaterials(values)),
			InstrumentsService.getInstruments().then(values => setInstruments(values)),
			MachinesService.getMachines().then(values => setMachines(values)),
		];
		Promise.all(promises).finally(() => setIsLoading(false));
	}

	useEffect(() => {
		loadStorageInformation();
	}, [])

	const loadInitialValues = () => {
		setIsLoading(true);
		ManufactureService.find(id).then(val => setInitialValues(val)).finally(() => {
			setIsLoading(false);
		});
	}

	useEffect(() => {
		if (id) {
			loadInitialValues();
		}
	}, [id])

	const dynamicFields = [
		{
			fieldsTemplate: (fields: any, name: any, index: any, change?: any) => {
				const {value: fieldsValues} = fields;
				const selected = fieldsValues.map((val: any) => val.id);
				const refactorEmployees = employees.map((emp) => ({
					...emp,
					name: emp.names,
				}))
				const employeeName = refactorEmployees.find((fv: any) => fv.id === fieldsValues[index]?.id)?.name;
				change(`${name}.employeeName`, employeeName || "");
				return (
					<Grid item xs={4} key={`${name}-${index}`}>
						<div key={name}>
							<TextField
								name={`${name}.employeeName`}
								type={"text"}
								required
								disabled
								style={{display: "none"}}
							/>
							{
								dynamicHeader("Персонал", index, () => fields.remove(index))
							}
							<Box marginBottom="10px">
								{
									getDefaultSelect(name, refactorEmployees, "Служител", selected || [])
								}
							</Box>
							<Box marginBottom="10px">
								<TextField
									name={`${name}.activity`}
									placeholder={`Дейност на служител ${index + 1}`}
									multiline
									rows={3}
									required
								/>
							</Box>
							<Box marginBottom="10px">
								<TextField
									label="Работни часове"
									name={`${name}.workingHours`}
									type={"number"}
									fieldProps={{
										validate: multipleValidators(
											validators.required,
											validators.mustBeNumber,
											validators.minNumber(0),
										)
									}}
								/>
							</Box>
						</div>
					</Grid>
				)
			},
			title: "Персонал",
			name: "employees",
			defaultModel: {id: null, workingHours: null, activity: null, employeeName: null},
		},
		{
			fieldsTemplate: (fields: any, name: any, index: any, change: any) => {
				const {value: fieldsValues} = fields;
				const selectedMaterials = fieldsValues.map((val: any) => val.id);
				const detailName = materials.find((fv: any) => fv.id === fieldsValues[index]?.id)?.name;
				change(`${name}.detailName`, detailName || "");
				return (
					<Grid item xs={4} key={`${name}-${index}`}>
						<div key={name}>
							<TextField
								name={`${name}.detailName`}
								type={"text"}
								required
								disabled
								style={{display: "none"}}
							/>
							{
								dynamicHeader("Материали", index, () => fields.remove(index))
							}
							<Box marginBottom="10px">
								{
									getDefaultSelect(name, materials, "Материал", selectedMaterials || [])
								}
							</Box>
							<Box marginBottom="10px">
								{
									getDefaultQuantity(name, materials, fieldsValues[index]?.id)
								}
							</Box>
						</div>
					</Grid>
				)
			},
			title: "Използвани материали",
			name: "materials",
			defaultModel: {id: null, quantity: null, detailName: null},
		},
		{
			fieldsTemplate: (fields: any, name: any, index: any, change: any) => {
				const {value: fieldsValues} = fields;
				const selected = fieldsValues.map((val: any) => val.id);
				const detailName = rawMaterials.find((fv: any) => fv.id === fieldsValues[index]?.id)?.name;
				change(`${name}.detailName`, detailName || "");
				return (
					<Grid item xs={4} key={`${name}-${index}`}>
						<div key={name}>
							{
								dynamicHeader("Суровини", index, () => fields.remove(index))
							}
							<Box marginBottom="10px">
								{
									getDefaultSelect(name, rawMaterials, "Суровина", selected)
								}
							</Box>
							<Box marginBottom="10px">
								{
									getDefaultQuantity(name, rawMaterials, fields.value[index]?.id)
								}
							</Box>
							<TextField
								name={`${name}.detailName`}
								type={"text"}
								required
								disabled
								style={{display: "none"}}
							/>
						</div>
					</Grid>
				)
			},
			title: "Използвани суровини",
			name: "rawMaterials",
			defaultModel: {id: null, quantity: null, detailName: null},
		},
		{
			fieldsTemplate: (fields: any, name: any, index: any, change: any) => {
				const {value: fieldsValues} = fields;
				const selected = fieldsValues.map((val: any) => val.id);
				const detailName = instruments.find((fv: any) => fv.id === fieldsValues[index]?.id)?.name;
				change(`${name}.detailName`, detailName || "");
				return (
					<Grid item xs={4} key={`${name}-${index}`}>
						<div key={name}>
							<TextField
								name={`${name}.detailName`}
								type={"text"}
								required
								disabled
								style={{display: "none"}}
							/>
							{
								dynamicHeader("Инструмент", index, () => fields.remove(index))
							}
							<Box marginBottom="10px">
								{
									getDefaultSelect(name, instruments, "Инструмент", selected)
								}
							</Box>
							<Box marginBottom="10px">
								{
									getDefaultQuantity(name, instruments, fields.value[index]?.id)
								}
							</Box>
						</div>
					</Grid>
				)
			},
			title: "Използвани инструменти",
			name: "instruments",
			defaultModel: {id: null, quantity: null, detailName: null},
		},
		{
			fieldsTemplate: (fields: any, name: any, index: any, change: any) => {
				const {value: fieldsValues} = fields;
				const selected = fieldsValues.map((val: any) => val.id);
				const detailName = machines.find((fv: any) => fv.id === fieldsValues[index]?.id)?.name;
				change(`${name}.detailName`, detailName || "");
				return (
					<Grid item xs={4} key={`${name}-${index}`}>
						<div key={name}>
							<TextField
								name={`${name}.detailName`}
								type={"text"}
								required
								disabled
								style={{display: "none"}}
							/>
							{
								dynamicHeader("Машина", index, () => fields.remove(index))
							}
							<Box marginBottom="10px">
								{
									getDefaultSelect(name, machines, "Машина", selected)
								}
							</Box>
							<Box marginBottom="10px">
								{
									getDefaultQuantity(name, machines, fields.value[index]?.id)
								}
							</Box>
						</div>
					</Grid>
				)
			},
			title: "Използвани машини и техника ",
			name: "machines",
			defaultModel: {quantity: null, id: null, detailName: null},
		}
	];

	const resetForm = ({reset}: any) => {
		reset();
	}

	const isAddButtonDisabled = (errors: any, values: any, name: string) => {
		return (errors[name] && errors[name].length > 0) || (values[name] && values[name].length === 5);
	}

	const renderForm = ({
		                    handleSubmit,
		                    form: {push, pop, reset, change},
		                    ...rest
	                    }: any) => {
		const {values, invalid, submitting, pristine, errors} = rest;
		return (
			<form noValidate onSubmit={handleSubmit}>
				<Box marginBottom="25px">
					<Grid container alignItems="flex-start" spacing={2}>
						{fields.map((item, idx) => (
							<Grid item xs={item.size as GridSize} key={idx}>
								{item.field}
							</Grid>
						))}
					</Grid>
				</Box>
				{
					dynamicFields.map((dynField, index) => {
						return (
							<Box marginBottom="25px" key={index}>
								<Box marginBottom="15px">
									<Box display={"flex"} alignItems="center" justifyContent="space-between">
										<Box marginRight="10px" className={classes.boxTitle}>
											<Typography variant="h6" color="inherit">{dynField.title}</Typography>
										</Box>
										{
											!initialValues.used && (
												<Box display="flex">

													<Box marginRight="10px">
														<Button
															type="button"
															variant="contained"
															color="primary"
															disabled={isAddButtonDisabled(errors, values, dynField.name)}
															onClick={() => push(dynField.name, dynField.defaultModel)}
														>
															Добави
														</Button>
													</Box>
													<Button
														type="button"
														variant="contained"
														color="secondary"
														onClick={() => pop(dynField.name)}
													>
														Премахни
													</Button>
												</Box>
											)
										}
									</Box>
									<Divider style={{marginTop: "10px"}}/>
								</Box>

								<Grid container alignItems="flex-start" spacing={2} className={classes.grid}>
									<FieldArray name={dynField.name} key={`${dynField.name}-${index}`}>
										{({fields}) =>
											fields.map((name, index) => dynField.fieldsTemplate(fields, name, index, change))
										}
									</FieldArray>
								</Grid>
							</Box>
						)
					})
				}
				{
					!initialValues.used && (
						<Box marginTop="20px" display={"flex"}>

							<Box marginRight="10px">
								<Button
									variant="contained"
									color="primary"
									type="submit"
									disabled={submitting || invalid}
								>
									{
										id ? "Редактирай" : "Добави"
									}
								</Button>
							</Box>

							<Button
								type="button"
								variant="contained"
								onClick={() => {
									resetForm({reset})
								}}
								disabled={submitting || pristine}
							>
								Нулиране
							</Button>
						</Box>
					)
				}
			</form>
		)
	}

	const handleSubmit = async (values: any) => {
		try {
			const data = new Manufacture(values);

			const handlerData: HandlerData = {
				[ManufactureKeys.instruments]: values.instruments,
				[ManufactureKeys.employees]: values.employees,
				[ManufactureKeys.machines]: values.machines,
				[ManufactureKeys.materials]: values.materials,
				[ManufactureKeys.rawMaterials]: values.rawMaterials,
			};

			const searchData: HandlerDataValues = {
				[ManufactureKeys.instruments]: instruments,
				[ManufactureKeys.employees]: employees,
				[ManufactureKeys.machines]: machines,
				[ManufactureKeys.materials]: materials,
				[ManufactureKeys.rawMaterials]: rawMaterials,
			};

			if (id) {
				const removeHandlerData: HandlerData = {
					[ManufactureKeys.instruments]: initialValues.instruments,
					[ManufactureKeys.employees]: initialValues.employees,
					[ManufactureKeys.machines]: initialValues.machines,
					[ManufactureKeys.materials]: initialValues.materials,
					[ManufactureKeys.rawMaterials]: initialValues.rawMaterials,
				};

				await ManufactureService.update(id, data);
				const handler = new ManufactureHandler(id, values.name, searchData);
				await handler.remove(removeHandlerData);
				await handler.store(handlerData);
			} else {
				setIsLoading(true);
				const docRef = await ManufactureService.store(data);
				const handler = new ManufactureHandler(docRef.id, values.name, searchData);
				await handler.store(handlerData);
			}

			toast.success(!id ? "Успешно добавяне" : "Успешно редактиране");
			push(RoutesUrl.manufacture.list);
		} catch (e) {
			toast.error("Нещо се обърка");
			console.error(e);
		}

	}

	const validate = (values: any) => {
		return validateForm(values, fields);
	}

	return (
		<CardComponent title={'Ново производство'} isLoading={isLoading}>
			<Form
				mutators={{
					...arrayMutators
				}}
				onSubmit={handleSubmit}
				validate={validate}
				initialValues={initialValues}
				render={({
					         handleSubmit, form: {
						mutators: {push, pop},
						reset,
						resetFieldState,
						change,
					}, ...rest
				         }) => renderForm({handleSubmit, form: {push, pop, reset, resetFieldState, change}, ...rest})}
			/>
		</CardComponent>
	)
}

export default withRouter(withStyles(styles)(NewManufacture));
