import {Model} from "../base.interface";
import {Condition} from "../../common/consts/Enums";
import BaseStorageModel from "./BaseStorageModel";

export default class Instrument extends BaseStorageModel implements Model {
	public condition!: Condition;

	constructor(instrument?: Instrument) {
		super(instrument);
		if(instrument) {
			this.condition = instrument.condition;
		}
	}

	dataToSave() {
		return {
			...this.getDefaultData(),
			condition: this.condition,
		}
	}
}

export class DbInstrument extends Instrument {
	public id: string;

	constructor(instrument: DbInstrument) {
		super(instrument);
		this.id = instrument.id;
	}
}
