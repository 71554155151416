import firebase from "../components/firebase";
import {Model} from "../models/base.interface";
import app from "firebase/app";
import Employee from "../models/Staff/Employee";

export type SearchBy = "<" | "<=" | "==" | "!=" | ">=" | ">" | "array-contains" | "in" | "array-contains-any" | "not-in";

export type CollectionResponse = firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>;

export interface FirestoreFilter {
	fieldPath: string | any;
	opStr: SearchBy;
	value: any;
}

export default class BaseService {
	protected collectionName: string;
	protected db: app.firestore.Firestore;

	constructor(collectionName: string) {
		this.collectionName = collectionName;
		this.db = firebase.db;
	}

	public store(values: Model) {
		return firebase.db.collection(this.collectionName).add(values.dataToSave());
	}

	async findAndUpdate(id: string, data: any): Promise<void> {
		try {
			return await this.db.collection(this.collectionName).doc(id).update(data);
		} catch(e) {
			console.error(e);
			throw e;
		}
	}

	async delete(id: string): Promise<void> {
		try {
			return await this.db.collection(this.collectionName).doc(id).delete();
		} catch(e) {
			console.error(e);
			throw e;
		}
	}

	async getWithArrayOfFilter<T>(filters: Array<FirestoreFilter>) {
		let q: firebase.firestore.Query<firebase.firestore.DocumentData> = this.db.collection(this.collectionName);
		for(const filter of filters) {
			const {fieldPath, opStr, value} = filter;
			q = q.where(fieldPath, opStr, value);
		}

		return this.returnData<T>(await q.get());
	}

	protected async returnData<T>(values: CollectionResponse) : Promise<Array<T>> {
		const items: Array<T> = [];
		values.forEach((val) => {
			const data = {id: val.id, ...val.data()} as unknown as T;
			items.push(data);
		});
		return items;
	}
}
