import Employee, {ManufactureEmployeeData} from "../../models/Staff/Employee";
import {ManufactureStorageData} from "../../models/Storage/BaseStorageModel";
import BaseService from "../../services/BaseService";
import {DbInstrument} from "../../models/Storage/Instrument";
import {DbMachine} from "../../models/Storage/Machine";
import {DbMaterial} from "../../models/Storage/Material";
import {DbRawMaterial} from "../../models/Storage/RawMaterial";
import InstrumentsService from "../../services/Storage/InstrumentsService";
import MachinesService from "../../services/Storage/MachinesService";
import MaterialsService from "../../services/Storage/MaterialsService";
import RawMaterialsService from "../../services/Storage/RawMaterialsService";
import EmployeeService from "../../services/Staff/EmployeeService";

type ManufactureData = ManufactureStorageData | ManufactureEmployeeData;

export enum ManufactureKeys {
	instruments = 1,
	machines,
	materials,
	rawMaterials,
	employees,
}

export interface HandlerData {
	[ManufactureKeys.instruments]: Array<ManufactureStorageData>;
	[ManufactureKeys.machines]: Array<ManufactureStorageData>;
	[ManufactureKeys.materials]: Array<ManufactureStorageData>;
	[ManufactureKeys.rawMaterials]: Array<ManufactureStorageData>;
	[ManufactureKeys.employees]: Array<ManufactureEmployeeData>;
}

export interface HandlerDataValues {
	[ManufactureKeys.instruments]: Array<DbInstrument>;
	[ManufactureKeys.machines]: Array<DbMachine>;
	[ManufactureKeys.materials]: Array<DbMaterial>;
	[ManufactureKeys.rawMaterials]: Array<DbRawMaterial>;
	[ManufactureKeys.employees]: Array<Employee>;
}

const services: { [key in ManufactureKeys]: BaseService} = {
	[ManufactureKeys.instruments]: InstrumentsService,
	[ManufactureKeys.machines]: MachinesService,
	[ManufactureKeys.materials]: MaterialsService,
	[ManufactureKeys.rawMaterials]: RawMaterialsService,
	[ManufactureKeys.employees]: EmployeeService,
}

export default class Handler {
	private readonly manufactureId: string;
	private readonly manufactureName: string;
	private readonly dataValues: HandlerDataValues;

	constructor(manufactureId: string, manufactureName: string, dataValues: HandlerDataValues) {
		this.manufactureId = manufactureId;
		this.manufactureName = manufactureName;
		this.dataValues = dataValues;
	}

	public async store(data: HandlerData) {
		for(const [key, value] of Object.entries(data)) {
			if(value.length) {
				await this.storeStorageFields(value, +key, +key === ManufactureKeys.employees);
			}
		}
	}

	public async remove(data: HandlerData) {
		for(const [key, value] of Object.entries(data)) {
			await this.removeAllUpdates(value, +key, +key === ManufactureKeys.employees);
		}
	}

	public async storeStorageFields(fieldsArray: Array<any>, key: ManufactureKeys, isEmployee = false): Promise<void> {
		for(const field of fieldsArray) {
			const search = this.dataValues[+key as ManufactureKeys] as Array<any>;
			const service = services[+key as ManufactureKeys];

			const {
				manufactureData = [],
				manufactureIds = [],
				quantity = 0,
			} = search.find((search) => search.id === field.id);

			const newStorage = this.extractModel(field, isEmployee);

			manufactureData.push(newStorage.dataToSave());
			manufactureIds.push(this.manufactureId);

			const dataToUpdate = Handler.getUpdateData(manufactureData, manufactureIds, newStorage, quantity);
			await service.findAndUpdate(field.id, dataToUpdate);
		}
	}

	public async removeAllUpdates(fieldsArray: Array<any>, key: ManufactureKeys, isEmployee = false): Promise<void> {
		for(const field of fieldsArray) {

			const search = this.dataValues[+key as ManufactureKeys] as Array<any>;
			const service = services[+key as ManufactureKeys];

			const dataField = search.find((search) => search.id === field.id);

			const removableEntities: Array<ManufactureData> = [];

			dataField.manufactureData.forEach((man: any) => {
				if(man.id === this.manufactureId) {
					removableEntities.push(man);
				}
			});

			if(removableEntities.length) {
				// we remove them from here because we dont know how many new entities the user has added
				dataField.manufactureData = dataField.manufactureData.filter((man: any) => man.id !== this.manufactureId);
				dataField.manufactureIds = dataField.manufactureIds.filter((id: string) => id !== this.manufactureId) || [];

				if(!isEmployee) {
					dataField.quantity = (removableEntities as Array<ManufactureStorageData>).reduce(((previousValue, currentValue) => {
						return previousValue + (+currentValue.quantity)
					}), +dataField.quantity)
				}
			}

			const updateData = {
				manufactureIds: dataField.manufactureIds,
				manufactureData: dataField.manufactureData,
			};

			if(!isEmployee) {
				Object.assign(updateData, {quantity: dataField.quantity});
			}

			await service.findAndUpdate(field.id, updateData);
		}
	}

	private static getUpdateData(manufactureData: any[], manufactureIds: any[], newStorage: ManufactureStorageData | ManufactureEmployeeData, quantity: number) {
		const dataToUpdate = {
			manufactureData,
			manufactureIds,
		};

		if (newStorage instanceof ManufactureStorageData) {
			const leftOverQuantity = quantity - newStorage.quantity;
			Object.assign(dataToUpdate, {quantity: leftOverQuantity});
		}

		return dataToUpdate;
	}

	private extractModel(field: any, isEmployee: boolean) {
		return !isEmployee ? new ManufactureStorageData({
			quantity: field.quantity,
			id: this.manufactureId,
			name: this.manufactureName,
			detailName: field.detailName,
		}) : new ManufactureEmployeeData({
			activity: field.activity,
			workingHours: field.workingHours,
			id: this.manufactureId,
			name: this.manufactureName,
			employeeName: field.employeeName,
		});
	}
}
