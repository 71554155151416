import React from "react";
import {momentFormat} from "../../common/Utils/FormUtils";
import moment from "moment";

export interface ExportableColumn {
	name: string;
	value: string;
}

type Props = {
	headers: Array<ExportableColumn>;
	data: Array<any>;
	title?: string;
}



export class TableComponentToPrint extends React.PureComponent<Props> {
	render() {
		return (
			<div style={{ padding: "10px" }}>
				{
					this.props.title && (
						<h2>{this.props.title}</h2>
					)
				}
				<table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid #eee"}}>
					<thead>
					{
						this.props.headers.map(({name}, index) => {
							return (
								<th key={index}>{name}</th>
							)
						})
					}
					</thead>
					<tbody>
					{
						this.props.data.map((item, index) => {
							return (
								<tr key={index}>
									{
										this.props.headers.map(({value: header}, headerIndex) => {
											const currentItem = item[header];
											const isDate = currentItem instanceof Date;

											const getVal = () => {

												if(isDate) {
													return moment(currentItem).format(momentFormat);
												}

												return currentItem.toString();
											}

											return (
												<td key={`value-${headerIndex}`}>{
													getVal()
												}</td>
											)
										})
									}
								</tr>
							)
						})
					}
					</tbody>
				</table>
			</div>
		);
	}
}
