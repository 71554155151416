import {Column} from "../../components/shared/TableComponent";
import {DbMaterial} from "../../models/Storage/Material";
import {DbRawMaterial} from "../../models/Storage/RawMaterial";
import {DbProduction} from "../../models/Storage/Production";
import {DbInstrument} from "../../models/Storage/Instrument";
import {DbMachine} from "../../models/Storage/Machine";
import {ExportableColumn} from "../../components/ComponentToPrint/TableComponent";
import moment from "moment";
import {momentFormat} from "./FormUtils";
import Manufacture from "../../models/manufacture/Manufacture";
import Employee from "../../models/Staff/Employee";
import {LabelKeyObject} from "react-csv/components/CommonPropTypes";

export type WorkingTypes = DbMaterial | DbRawMaterial | DbProduction | DbInstrument | DbMachine;

export function getColumnsForExport(columns: Array<Column>): Array<ExportableColumn> {
	return columns.filter((c) => c.accessor).map((col) => {
		return {
			name: col.header,
			value: col.accessor
		}
	}) as Array<ExportableColumn>;
}

export function getDataForExport(data: WorkingTypes[]) {
	return data.map((item) => item.dataToSave());
}

export function getManufactureColumnsForExport(): Array<ExportableColumn> {
	return [
		{
			value: "name",
			name: "Наименование",
		},
		{
			value: "quantity",
			name: "Количество",
		},
		{
			value: "startDate",
			name: "Начална дата",
		},
		{
			value: "endDate",
			name: "Крайна дата",
		},
	];
}

export function getManufactureDataForExport(data: Array<Manufacture>){
	return data.map((item) => {
		return {
			name: item.name,
			quantity: item.quantity,
			startDate: moment(item.startDate).format(momentFormat),
			endDate: item.endDate ? moment(item.endDate).format(momentFormat) : "",
		}
	});
}

export function getEmployeeColumnsForExport(): Array<ExportableColumn> {
	return [
		{
			name: "Имена",
			value: "names"
		},
		{
			name: "Дата на започване",
			value: "start_date",
		},
		{
			name: "Отдел",
			value: "department"
		},
		{
			name: "Позиция",
			value: "position",
		},
		{
			name: "Други",
			value: "other",
		},
		{
			name: "Общо работни часове",
			value: "total_working_hours",
		}
	];
}

export function getEmployeeDataForExport(data: Array<Employee>) {
	return data.map((item) => {
		return {
			names: item.names,
			start_date: moment(item.start_date).format(momentFormat),
			department: item.department,
			position: item.position,
			other: item.other,
			total_working_hours: item.totalWorkingHours(),
		}
	})
}


export function getColumns(columns: Array<ExportableColumn>): Array<LabelKeyObject> {
	return columns.map((col) => {
		return {
			label: col.name,
			key: col.value,
		}
	});
}
